#reasons{
  background-color: #5abad2;
  padding-top: 170px;

  @media $tablet {
    padding-top: 100px;
  }
  @media $mobile {
    padding-top: 0;
  }

  .wrapper{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1420px;
    width: calc(100% - 20px);
    height: 570px;
  }

  .section-title_wrapper{
    position: absolute;
    margin-top: -100px;
    background-color: #5abad2;
  }
  .section-title{
    color: #fff;
    border-bottom: 1px dotted #fff;
  }
  .content-mobile{  display: none;  }
  .content-desktop{ display: inline-block; }

  .reason-lines_wrapper{
    position: absolute;
    width: calc(100% - 20px);
    max-width: 1400px;
    height: 220px;
  }
  .reason-line{
    position: absolute;
    display: block;
    border-color: #88CDDD;
  }
  .reason-line_1{
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 2px solid #88CDDD;
    width: 25%;
    height: 220px;
    margin-top: 0%;
  }
  .reason-line_2{
    border-top: none;
    border-right: 2px solid #88CDDD;
    border-bottom: 2px solid #88CDDD;
    border-left: 2px solid #88CDDD;
    border-radius: 0 0 50% 50%;
    width: 25%;
    height: 280px;
    margin-top: 220px;
  }
  .reason-line_3{
    border-radius: 50% 50% 0 0;
    border-top: 2px solid #88CDDD;
    border-right: 2px solid #88CDDD;
    border-bottom: none;
    border-left: 2px solid #88CDDD;
    width: 25%;
    height: 280px;
    margin-top: 75px;
    margin-left: calc(25% - 2px);
  }
  .reason-line_4{
    border-radius: 0 0 50% 50%;
    border-top: none;
    border-right: 2px solid #88CDDD;
    border-bottom: 2px solid #88CDDD;
    border-left: 2px solid #88CDDD;
    width: 25%;
    height: 280px;
    margin-top: 220px;
    margin-left: calc(50% - 4px);
  }
  .reason-line_5{
    border-radius: 50% 50% 0 0;
    border-top: 2px solid #88CDDD;
    border-right: 2px solid #88CDDD;
    border-bottom: none;
    border-left: 2px solid #88CDDD;
    width: 25%;
    height: 300px;
    margin-top: 75px;
    margin-left: calc(75% - 6px);
  }
  .reason-line_6 {
    border-top: none;
    border-right: 2px solid #88CDDD;
    border-bottom: none;
    border-left: none;
    width: 25%;
    height: 350px;
    margin-top: 220px;
    margin-left: calc(75% - 6px);
  }
  .handset-decor{
    position: absolute;
    display: block;
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    height: 100%;
    margin-top: -50px;
    margin-left: -70px;
    background-image: url(/i/logo_handset.png);
    background-size: cover;
    left: 100%;
    top: 600px;
    z-index: 30;

    @media(max-width: 1580px) {
      display: none;
    }
    @media(max-width: 720px) {
      display: block;
    }
  }

  .reason-wrapper{
    display: inline-block;
    position: absolute;
    margin-top: 165px;
    width: 25%;
    height: 100px;
  }
  .reason-wrapper_1{ margin-left: 0%; }
  .reason-wrapper_2{ margin-left: 25%; }
  .reason-wrapper_3{ margin-left: 50%; }
  .reason-wrapper_4{ margin-left: 75%; }

  .reason {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    &__number {
      position: absolute;
      transform: translate(-50%, -50%);
      color: #ffffff;
      border: 2px solid #88CDDD;
      background-color: #5ABAD2;
      border-radius: 50%;
      font-family: 'ProximaNova_Bold', sans-serif;
      font-weight: bold;
      text-align: center;
      padding-top: 2px;
      font-size: 2.4rem;
      width: 36px;
      height: 36px;
      cursor: default;

      &_pos_up {
        margin-top: -140px;
      }
      &_pos_down {
        margin-top: 287px;
      }

    }
    &__icon {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-repeat: no-repeat;
      transition 0.3s;
    }
    &__circle-front {
      position: absolute;
      background-color: #BDE3EC;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.13);
      transition 0.45s;
    }
    &__circle-back {
      position: absolute;
      background-color: #88CDDD;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
      transition 0.66s;
    }
    &__caption{
      position: absolute;
      display: inline-block;
      text-align: center;
      font-family: 'ProximaNova_Regular', Sans-Serif;
      font-weight: 400;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      margin-top: 64px;
      color: #fff;
      white-space: nowrap;
    }
    &__tooltip:before{
      position: absolute;
      z-index: 301;
      content: '';
      display: block;
      margin-top: -20px;
      margin-left: calc(50% - 28px);
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-right-width: 16px;
      border-left-width: 16px;
      border-bottom: 16px solid #5abad2;
    }
    &__tooltip{
      position: absolute;
      z-index: 300;
      display: inline-block;
      text-align: center;
      font-family: 'ProximaNova_Regular', Sans-Serif;
      font-weight: 400;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      padding: 8px 16px;
      border-radius: 24px;
      border: 2px solid #5abad2;
      background-color: #5abad2;
      color: #fff;
      width: 20vw;
      max-width: 20vw;
      font-size: 1.6rem;
      opacity: 0;
      margin-top: 25px;
      box-shadow: 0 0 100px 5px rgba(0,0,0,0.11);
      transition: .66s;
    }
  }
  // мини-анимация при наведении на блок
  .reason-wrapper:hover{
    .reason{

      &__circle-front{
        width 54px;
        height 54px;
      }
      &__circle-back  {
        width 86px;
        height 86px;
      }
      &__icon{
        width: 25px;
        height: 18px;
      }
      &__caption {
        display: none;
      }
      &__tooltip{
        opacity: 1;
        margin-top: 55px;
        cursor: default;
      }
    }
  }



  /* альтернативный вариант верстки для IE + Edge */
  .ie_reasons_wrapper{
    width: 100%;
    position: relative;
    display: none;
  }
  .ie_reason{
    vertical-align: top;
    display: inline-block;
    width: 24.9%;
    height: 570px;
  }
  .ie_reason__caption{
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 280px;
    text-align: center;
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
    color: #fff;
  }
  .ie_reason__tooltip:before{
    position: absolute;
    z-index: 301;
    content: '';
    display: block;
    margin-top: -20px;
    margin-left: calc(9.0% - 8px);
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 16px;
    border-left-width: 16px;
    border-bottom: 16px solid #5abad2;
  }
  .ie_reason__tooltip {
    display: none;
    text-align: center;
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 24px;
    border: 2px solid #5abad2;
    background-color: #5abad2;
    color: #fff;
    font-size: 1.6rem;
    margin-top: 280px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 0 100px 5px rgba(0,0,0,0.11);
    transition: .66s;
  }
  .ie_reason:hover,
  .ie_reason:focus{
    .ie_reason__tooltip{  display: block; }
    .ie_reason__caption { display: none;  }
  }


  @media(max-width: 1024px) { //lg

    .section-title_wrapper{
      padding-left: 5%;
      margin-top: -64px;
    }
    .reason-line_1{
      display: none !important;
    }
    .section-title{
      border-bottom: none;
    }

    .reason{
      &__caption{
        white-space: normal;
        width: 20vw;
        max-width: 20vw;
        font-size: 1.6rem;
      }
      &__tooltip {
        transform: translate(-50%, -50%);
        top: 50%;
        margin-top: 45px;
        font-size: 1.6rem;
        width: 20vw;
        max-width: 20vw;
        margin-left: -5px;
      }
      &__tooltip:before{
        display none;
      }
    }

    .reason-wrapper:hover {
      .reason {
        &__circle-front{
          opacity: 0.33;
        }
        &__circle-back{
          opacity: 0.33;
        }
        &__icon{
          opacity: 0.8;
        }
        &__tooltip {
          margin-top: 90px;
        }
        &__tooltip:before {

        }
      }
    }

    .ie_reason__caption,
    .ie_reason__tooltip{
      font-size: 1.6rem;
    }

  }
  @media(max-width: 900px) {

    .ie_reason__tooltip{
      margin-top: 120px;
    }
    .ie_reason__tooltip:before{
      display: none;
    }

  }
  @media(max-width: 800px) { //md

  }
  @media(max-width: 768px) { //md

  }
  @media(max-width: 720px) { // <md

    .wrapper{
      height: 900px;
    }
    .section-title_wrapper{
      width: calc(100% - 20px);
      margin-top: 40px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      display: block;
      text-align: center;
    }
    .reason-wrapper_1,
    .reason-wrapper_2,
    .reason-wrapper_3,
    .reason-wrapper_4{
      margin-left: 0;
    }
    .section-title{
      font-size: 2.4rem;
      letter-spacing: 0.66px;
      color: #fff;
    }
    .reason-wrapper{
      margin-top: 0px;
      display: block;
      width: 100%;
      height: 190px;
      position relative;
    }
    .reason{
      margin-top: 32px;

      &__number{
        // не показываем номера пунктов на мобильных
        display: none !important;
      }
      &__caption{
        font-size: 1.6rem;
        width: 480px;
        max-width: 480px;
      }
      &__icon {
        margin-left: 0px;
        width: 16px;
        height: 14px;
      }
      &__circle-front {
        width: 35px;
        height: 35px;
      }
      &__circle-back {
        width: 55px;
        height: 55px;
      }
      &__tooltip{
        transform: translate(-50%, -50%);
        margin-top: 105px;
        width: 80vw;
        max-width: 80vw;
        margin-left: 0;
      }
    }
    .reason-wrapper:hover{
      .reason{
        &__icon {
          width: 18px;
          height: 16px;
          opacity: 1;
        }
        &__circle-front {
          width: 37px;
          height: 37px;
          opacity: 1;
        }
        &__circle-back {
          width: 58px;
          height: 58px;
          opacity: 1;
        }
        &__tooltip{
          margin-left: 0;
          margin-top: 86px;
        }
        &__tooltip:before{
          margin-left: calc(50% - 32px);
          display: block;
        }
      }
    }

    .reason-line_1,
    .reason-line_2,
    .reason-line_3,
    .reason-line_4,
    .reason-line_5,
    .reason-line_6{
      border-radius: 0;
      margin-left: 0;
      height: 190px;
    }

    .reason-line_1{
      left: 5%;
      width: 45%;
      margin-top: 0px;
      height: 132px;
      border-top: 2px solid transparent;
      border-right: 2px solid transparent;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
      border-radius: 0 0 0 120px;
    }
    .reason-line_2 {
      right: 5%;
      width: 45%;
      margin-top: calc(190px - 60px);
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid transparent;
      border-radius: 0 120px 120px 0;
    }
    .reason-line_3 {
      left: 5%;
      width: 45%;
      margin-top: calc(190px * 2 - 60px);
      border-top: 2px solid #88CDDD;
      border-right: 2px solid transparent;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
      border-radius: 120px 0 0 120px;
    }
    .reason-line_4 {
      right: 5%;
      width: 45%;
      margin-top: calc(190px * 3 - 60px);
      margin-left: 0px;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid transparent;
      border-radius: 0 120px 120px 0;
    }
    .reason-line_5 {
      left: 5%;
      right: auto;
      width: 41%;
      margin-top: calc(190px * 4 - 60px);
      height: 160px;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid transparent;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
      border-radius: 120px 0 0 120px;
    }
    .reason-line_6 {
      left: 38%;
      right: auto;
      width: 9%;
      //height: 95px;
      height: 43px;
      margin-top: calc(190px * 5 - 95px + 3px);
      margin-left: 20px;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      border-radius: 0 30px 0 0;
      opacity: 1;
      z-index: 10;
    }
    .handset-decor {
      max-width: 72px;
      max-height: 72px;
      left: 50%;
      margin-left: -40px;
      top: 937px;
      z-index: 30;
    }

    /* IE/Edge alternative markup version */
    .ie_reason{
      width: 100%;
      height: 190px;
    }
    .ie_reason_1{
      margin-top: 100px;
    }
    .ie_reason__caption{
      margin-top: 80px;
    }
    .ie_reason__tooltip {
      margin-top: 64px;
    }
    .ie_reason__tooltip:before {
      display: block;
      left: calc(50% - 56px - 8px);
    }


  }
  @media(max-width: 568px) {  //sm

    .section-title_wrapper{
      margin-top: 32px;
    }
    .reason {
      &__number {
        // не показываем номера пунктов на мобильных
        display: none !important;
      }
      &__caption {
        font-size: 1.4rem;
        width: 280px;
        max-width: 280px;
      }
      &__tooltip {
        font-size: 1.4rem;
        width: 280px;
        max-width: 280px;
      }
    }
  }
  @media(max-width: 320px) { //xs

  }
}
