main.book{

  .get{
    display: block;
    height: 770px;

    .section-title{
      padding-top: 110px;
      color: #59bad1;
      margin-bottom: 74px;
    }
    .chain-list{
      margin-bottom: 98px;
    }

    .wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .block{
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
    }
    .block_a{
      padding-right: 80px;

      .section-title{
        display: block;
      }
    }
    .block_b{
      background-image: url('/i/book_06_demo_bg.jpg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: -24px -24px 64px rgba(255,255,255,1) inset;

      .section-title{
        display: none;
      }
    }

    @media only screen and (max-width: 1024px){
      height: auto;

      .wrapper{
        flex-direction: column;
      }
      .block{
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
      .block_a{
        padding-bottom: 32px;
        padding-right: 0;

        .section-title{
          display: none;
        }
      }
      .block_b{
        min-height: 320px;
        max-height: 640px;
        height: calc(100vw - 240px);
        background-size: cover;
        background-position-y: 33%;
        order: -1;

        .section-title{
          padding-top: 40px;
          display: block;
          padding-bottom: 140px;
          background-color: rgba(255,255,255,0.8);
          background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 27%, rgba(255,255,255,0.9) 44%, rgba(255,255,255,0.66) 60%, rgba(255,255,255,0.1) 85%, rgba(255,255,255,0) 100%);
          background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 27%,rgba(255,255,255,0.9) 44%,rgba(255,255,255,0.66) 60%,rgba(255,255,255,0.1) 85%,rgba(255,255,255,0) 100%);
          background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 27%,rgba(255,255,255,0.9) 44%,rgba(255,255,255,0.66) 60%,rgba(255,255,255,0.1) 85%,rgba(255,255,255,0) 100%);
        }
      }
      .chain-list{
        margin-bottom: 40px;
      }
    }
    @media only screen and (max-width: 768px){
      .block_a {
      }
      .block_b{
        background-position-y: center;
      }
      .btn_worksheet{
        display: block;
        margin-right: auto;
        margin-left: auto;
      }
    }
    @media only screen and (max-width: 480px){
      .block_a{
        margin-top: -40px;
      }
      .block_b{
        background-position-y: center;
        box-shadow: -12px -12px 24px rgba(255,255,255,1) inset;
      }
    }
  }

}