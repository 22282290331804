
/*   -=========== Fonts ===========-   */

@font-face {
  //font-family: 'Proxima Nova';
  font-family: 'ProximaNova_Light';
  font-weight: 300;
  src: url('/i/fonts/ProximaNova_Light.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/ProximaNova_Light.woff') format('woff'),
       url('/i/fonts/ProximaNova_Light.woff2') format('woff2'),
       url('/i/fonts/ProximaNova_Light.ttf') format('truetype');
}

@font-face {
  //font-family: 'Proxima Nova';
  font-family: 'ProximaNova_Regular';
  font-weight: 400;
  src: url('/i/fonts/ProximaNova_Regular.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/ProximaNova_Regular.woff') format('woff'),
       url('/i/fonts/ProximaNova_Regular.woff2') format('woff2'),
       url('/i/fonts/ProximaNova_Regular.ttf') format('truetype');
}

@font-face {
  //font-family: 'Proxima Nova';
  font-family: 'ProximaNova_Bold';
  font-weight: 600;
  src: url('/i/fonts/ProximaNova_Bold.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/ProximaNova_Bold.woff') format('woff'),
       url('/i/fonts/ProximaNova_Bold.woff2') format('woff2'),
       url('/i/fonts/ProximaNova_Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'rouble';
  src: url('/i/fonts/rouble-webfont.eot');
  src: url('/i/fonts/rouble-webfont.eot?#iefix') format('embedded-opentype'),
       url('/i/fonts/rouble-webfont.woff') format('woff'),
       url('/i/fonts/rouble-webfont.ttf') format('truetype');
}

.rouble{
  font-family: 'rouble';
}