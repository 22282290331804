#article_one {
  display: block;
  width: 100%;
  background-color: #F8F8F8;
  padding-bottom: 24px;

  .article-header-background{
    position: absolute;
    display: block;
    top: 120px;
    width: 100%;
    height: 568px;
    background-color: #5abad2;
    background-size: cover;
    background-image: url(/i/fon_460.jpg);
    z-index: 0;
  }
  .article-header-tint {
    blend-mix-mode: soft-light;
    background-color: #9ad3e6;
    width: 100%;
    height: 100%;
    opacity: 0.72;
  }
  .wrapper{
    //max-width: 1420px;
    max-width: 1280px;
    background-color: #fff;
    margin-top: 80px;
  }
  .article-header{
    position: relative;
    display: block;
    width: 100%;
    height: 548px;
    background-color: #fff;
    background-size: cover;
    background-position-y: center;
    background-image: url(/i/fon_460.jpg);
  }
  .article-img-shadow{
    position: absolute;
    background-color: rgba(0,0,0,.25);
    width: 100%;
    height: 100%;
  }
  .article-title{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: #fff;
    font-size: 3.6rem;
    font-family: 'ProximaNova_Bold', Sans-Serif;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
  }
  .article-body{
    padding-top: 80px;
    padding-left: 140px;
    padding-right: 140px;
    padding-bottom: 90px;
    line-height: 1.5;
    color: #666666;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.09);
  }
  .article-time{
    display: block;
    text-align: right;
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
  }

  .more-articles_wrapper {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 64px;
  }

  @media (max-width: 1500px) {
    .article-body{
      box-shadow: none;
    }
  }
  @media (max-width: 1280px) {

    .article-title{
      font-size: 3.4rem;
    }
    .article-body{
      padding-left: 120px;
      padding-right: 120px;
    }
  }

  @media (max-width: 1024px) {

    .article-header-background{
      top: 64px;
    }
    .article-title{
      font-size: 3.2rem;
    }
    .article-body{
      padding-top: 8%;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 9%;
      font-size: 1.6rem;
    }
    .more-articles_wrapper {
      text-align: center;
      padding-top: 80px;
      padding-bottom: 48px;
    }
  }
  @media (max-width: 768px) {

    .more-articles_wrapper {
      padding-top: 60px;
      padding-bottom: 32px;
    }
  }
  @media (max-width: 720px) {
    .wrapper{
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .article-header-background{
      height: 548px;
    }
  }
  @media (max-width: 320px) {

    .article-header-background,
    .article-header{
      height: 404px;
    }
    .more-articles_wrapper {
      padding-top: 48px;
      padding-bottom: 0px;
    }
  }
}

.readPercentIndicator{
  position: fixed;
  display: block;
  z-index: 100;
  background-color: #5abad2;
  height: 4px;
  width: 0%;
  top: 0;
}

.checklist-request-incut {
  padding: 2em;
  background: #5abad2;
  color: #fff;

  @media only screen and (max-width: 1200px) {
    text-align: center;

    .btn_report-sample {
      margin-top: 10px !important;
    }
  }
}
