main.book{

  .interviews{
    display: block;
    background-color: #f4f4f4;
    height: 600px;
    padding-top: 84px;

    .section-title{
      color: #59bad1;
      margin-bottom: 28px;
    }
    p{
      font-weight: 500;
      font-family: 'ProximaNova_Regular', Sans-Serif;
    }
    .interviews-wrapper{
      padding-top: 40px;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-wrap: nowrap;

      .name{
        color: #59bad1;
        font-family: "ProximaNova_Bold", Sans-Serif;
        font-weight: 700;
        font-size: 15px;
        text-transform: uppercase;
      }
      .interview{
        flex-basis: 25%;
        max-width: 25%;
        width: 25%;

        .description{
          text-align: center;
          font-size: 15px;
          line-height: 1.0;

          strong{
            color: #333333;
          }
        }
        .row_1{
          height: 188px;
          text-align: center;
        }
        .row_2{
          height: 32px;
          text-align: center;
        }
        .row_3{
          height: 48px;
          text-align: center;
        }

        .border {
          display: inline-block;
          padding: 12px;
          width: 128px;
          height: 128px;
          box-sizing: content-box;
          border-radius: 50%;
          background-color: #ffffff;
          border: 2px solid #59bad1;
        }
        .img {
          display: inline-block;
          border-radius: 50%;
          min-width: 128px;
          min-height: 128px;
          width: 128px;
          height: 128px;
          box-sizing: content-box;
        }

      }

    }
    @media only screen and (max-width: 1023px){
      height: auto;

      .interviews-wrapper{
        flex-wrap: wrap;
        padding-bottom: 40px;

        .interview {
          flex-basis: 50%;
          max-width: 50%;
          width: 50%;
          padding-bottom: 48px;

          .description{
            line-height: 1.33;
          }
        }
      }
    }
    @media only screen and (max-width: 375px){
      .interviews-wrapper{
        flex-wrap: wrap;
        padding-bottom: 40px;

        .interview {
          flex-basis: 100%;
          max-width: 100%;
          width: 100%;
          padding-bottom: 24px;
        }
      }
    }
  }

}