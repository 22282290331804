#stages {
  background-color: #5ABAD2;
  font-family: 'ProximaNova_Regular', sans-serif;
  text-align: center;
  font-size: 1.8rem;
  //height: 2450px;
  //min-height: 2450px;
  height: 2350px;
  min-height: 2350px;

  .section-title {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.8rem;
    letter-spacing: 3px;
    position: absolute;
    display: inline-block;
    text-align: center;
    transform: translate(-50%, -50%);
    margin-top: 145px;
    left: 50%;

    &_wrapper {
      height: 200px;
    }
  }

  .step {
    display: block;
    padding-top: 205px;
    box-sizing: border-box;

    &_1 {
      height: 420px;
    }
    &_2 {
      height: 384px;
    }
    &_3 {
      height: 400px;
    }
    &_4 {
      height: 390px;
    }
    &_final {
      height: 300px;
    }

    &__icon-sm{
      display: none;
    }
    &__title {
      position: absolute;
      display: inline-block;
      transform: translate(-50%, -50%);
      left: 50%;
      margin-top: -160px;
      color: #ffffff;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    &__number {
      position: absolute;
      transform: translate(-50%, -50%);
      color: #ffffff;
      border: 2px solid #88CDDD;
      background-color: #5ABAD2;
      border-radius: 50%;
      font-family: 'ProximaNova_Bold', sans-serif;
      font-weight: bold;
      text-align: center;
      font-size: 2.4rem;
      width: 36px;
      height: 36px;
      padding-top: 2px;
      cursor: default;

      &_pos_left {
        margin-left: 35%;
      }
      &_pos_center {
        margin-left: 50%;
      }
      &_pos_right {
        margin-left: 65%;
      }
    }

  }
  .step_2 {
    padding-top: 188px;

    .step__title {
      margin-top: -140px;
    }
  }

  .stage { // парящий круг с иконкой
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    &_pos_left {
      margin-left: 20%;
    }
    &_pos_midleft {
      margin-left: 35%;
    }
    &_pos_center {
      margin-left: 50%;
    }
    &_pos_midright {
      margin-left: 65%;
    }
    &_pos_right {
      margin-left: 80%;
    }

    &__icon { // svg-иконка этапа
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      font-family: 'ProximaNova_Bold', Sans-Serif;
      font-weight: 600;
      color: #fff;
      font-size: 3.8rem;
    }
    &__circle-front { // подложка для иконки
      position: absolute;
      background-color: #BDE3EC;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.13);
    }
    &__circle-back { // кружок на заднем плане
      position: absolute;
      background-color: #88CDDD;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.04);
    }
    &__caption { // подпись под каждым этапом
      position: absolute;
      display: inline-block;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      margin-top: 140px;
      color: #fff;
      white-space: nowrap;
    }
    &__caption_trigger{
      .btn_trigger{
        margin-bottom: 2px;
      }
    }
  }
  .step_final {

    .step__title {
      text-align: left;
      width: 150px;
      margin-left: 26%;
      margin-top: 120px;
      background-color: rgba(90, 186, 210, 0.5);
      padding: 5px;
      box-sizing: content-box;
    }

    .stage {
      &__icon {}
      &__circle-front {
        background-color: #88CDDD;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.26),
            0 0 6px 0 rgba(0, 0, 0, 0.26);
      }
      &__circle-back {
        background-color: #BDE3EC;
        box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.04);
      }
      &__caption {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 19px 18px 12px 18px;
        margin-top: 216px;
        background-color: #5ABAD2;
        border-radius: 50px;
        border: 2px solid #88CDDD;
      }
    }
  }

  .stage-lines_wrapper {
    display: inline-block;
    position: absolute;
    width: calc(100% - 20px);
    max-width: 1420px;
    height: 100px;
    left: 0;
    right: 0;
    margin: auto;

    .stage-line {
      position: absolute;
      display: block;
      width: 50%;
      border-color: #88CDDD;
      height: 402px;
    }

    .stage-line_1 {
      margin-left: 0;
      margin-top: 0;
    }
    .stage-line_2 {
      margin-left: 50%;
      margin-top: 408px;
    }
    .stage-line_3 {
      margin-left: 0;
      margin-top: calc(402px * 2);
    }
    .stage-line_4 {
      margin-left: 50%;
      margin-top: calc(402px * 3);
    }
    .stage-line_5 {
      margin-left: 0;
      margin-top: calc(402px * 4);
    }
    .stage-line_6 {
      margin-left: 50%;
      margin-top: calc(402px * 5);
    }

    .stage-line_1 {
      border-radius: 0 0 0 200px;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
    }
    .stage-line_2 {
      border-radius: 0 200px 200px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
    .stage-line_3 {
      border-radius: 200px 0 0 200px;
      border-top: 2px solid #88CDDD;
      border-right: none;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
    }
    .stage-line_4 {
      border-radius: 0 200px 200px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
    .stage-line_5 {
      border-radius: 200px 0 0 200px;
      border-top: 2px solid #88CDDD;
      border-right: none;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
    }
    .stage-line_6 {
      width: 20%;
      height: 210px;
      border-radius: 0 100px 100px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
  }
  .wrapper-666,
  .moreinfo-arrow-666{
    display: none;
  }


  @media (max-width: 1280px) { //xl

    .stage {
      &__caption {
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 1024px) { //lg
    font-size: 1.6rem;

    .step {
      &__number {
        font-size: 1.8rem;
        width: 32px;
        height: 32px;
        padding-top: 4px;
      }
    }

    .stage {
      &__icon {
        background-size: 85%;
        background-repeat: no-repeat;
        background-position: center center;
        font-size: 3.2rem;
      }
      &__circle-front {
        width: 110px;
        height: 110px;
      }
      &__circle-back {
        width: 150px;
        height: 150px;
      }
      &__caption {
        width: 100%;
        max-width: 300px;
        text-align: center;
        white-space normal;
      }
    }
    .step_final {
      .stage {
        &__caption {
          max-width: 290px;
        }
      }
    }
  }
  @media (max-width: 800px) { //md

    .stage {
      &__caption {
        margin-top: 100px;
        max-width: 240px;
      }
      .stage__icon {
        font-size: 3.0rem;
      }
    }
    .step_final {
      .stage {
        &__caption {
          max-width: 290px;
        }
      }
    }
  }
  @media (max-width: 768px) { //md

    .stage {
      &__caption {
        max-width: 200px;
      }
      .stage__icon {
        font-size: 3.0rem;
      }
    }
    .step_final {
      .stage {
        &__caption {
          max-width: 290px;
        }
      }
    }
  }
  @media (max-width: 720px) { //sm

    .section-title {
      font-size: 2.2rem;
      letter-spacing: 0.425px;
    }

    .stage {
      &__caption {
        font-size: 1.4rem;
      }
      &__circle-front {
        width: 96px;
        height: 96px;
      }
      &__circle-back {
        width: 128px;
        height: 128px;
      }
    }

    .stage_final {
      .stage__icon {
        font-size: 2.8rem;
      }
    }
  }
  @media (max-width: 666px) { //xs < iPhone6 landscape
    height 1024px;
    min-height: 1024px;

    .section-title_wrapper{
      height: 100px;
    }
    .section-title{
      margin-top: 55px;
    }
    .stage {
      display: none;
    }
    .step__number{
      display: none;
    }
    .step {
      height: 220px;

      &__icon-sm{
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        color: #ffffff;
        border: 2px solid #88CDDD;
        background-color: #5ABAD2;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding-top: 2px;
        margin-left: 50%;
      }
      &__icon-sm:before{
        content: '';
        position absolute;
        width: 18px;
        height: 18px;
        margin-top: 7px;
        margin-left: -8px;
        background-image: url(/i/icon-success_white.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      &_1 {
        .step__icon-sm{ margin-top: -55px; }
      }
      &_2 {
        .step__icon-sm{ margin-top: -40px; }
      }
      &_3 {
        .step__icon-sm{ margin-top: -60px; }
      }
    }
    .step_final {
      .step__title{
        display: none;
      }
    }
    .stage-lines_wrapper {
      .stage-line_1 {
        border-radius: 0 0 0 100px;
        height: 252px;
      }
      .stage-line_2 {
        height: 220px;
        margin-top: 250px;
      }
      .stage-line_3 {
        height: 220px;
        margin-top: calc(220px * 2 + 28px);
      }
      .stage-line_4 {
        width: calc(50% + 10px);
        height: 10px;
        margin-top: calc(220px * 3 + 26px);
        border-radius: 0;
        border-right: none;
        border-bottom: none;
      }
      .stage-line_5 {
        display: none;
      }
      .stage-line_6 {
        display: none;
      }
    }
    .block-666{
      display: block;
    }
    .hide-666{
      display: none;
    }
    .reg-666{
      font-family: 'ProximaNova_Regular', sans-serif;
      font-weight: 400;
      letter-spacing: 0.45px;
    }
    .wrapper-666{
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: -300px;
      text-align: center;
      z-index: 1;
    }
    .moreinfo-arrow-666{
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 24px;
      margin-top: 12px;
      margin-left: -10px;
      transform: rotate(45deg);
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
    }
    .wr-666-1{
      display: block;
    }
    .wr-666-2{
      display: none;
    }

  }
  @media (max-width: 568px) { //xs

    // все равно не очень выглядит на маленьких разрешениях
    .step_final {
      .stage {
        &__caption {
          max-width: 230px;
        }
        .btn_trigger{
          height: 50px;
        }
      }
    }

  }
}


@media (max-width: 666px) {

  .mobile_expanded {
    height: 3260px !important;
    min-height: 3260px !important;

    .step__icon-sm {
      display: none !important;
    }
    .stage {
      display: block !important;
      margin-left: 50% !important;
      height: 215px;
      min-width: 300px;
    }
    .stage__icon,
    .stage__circle-front,
    .stage__circle-back {
      margin-top: -50px !important;
    }
    .stage__caption {
      margin-top: 28px !important;
    }
    .step_1 {
      height: 740px !important;
    }
    .step_2 {
      height: 510px !important;
    }
    .step_3 {
      height: 740px !important;
    }
    .step_4 {
      height: 870px !important;
    }
    .stage_3-2 .stage__circle-front {
      top: 89.5% !important;
    }
    .stage_3-2 .stage__icon {
      top: 100% !important;
    }
    .stage_4-2 {
      height: 300px !important;
    }
    .stage_final {
      margin-top: -100px;
      height: 260px;
    }
    .step_final {
      height: 300px !important;
    }
    .stage__caption_trigger {
      display: none !important;
    }
    .wrapper-666 {
      position: absolute;
      width: 100%;
      margin-top: -24px !important;
    }
    .wr-666-1 {
      display: none !important;
    }
    .moreinfo-arrow-666 {
      display: none !important;
    }
    .wr-666-2 {
      display: block !important;
    }

    // lines
    .stage-line_m{
      position: absolute;
      display: block;
      width: 50%;
      border-color: #88CDDD;
      height: 215px;
    }
    // line 2
    .stage-line_m2-1 {
      margin-left: -10px;
      margin-top: 980px;
    }
    .stage-line_m2-2 {
      margin-left: 50%;
      margin-top: 980px;
    }
    .stage-line_m2-3 {
      margin-left: -10px;
      margin-top: 1195px;
    }
    .stage-line_m2-1 {
      border-top: 2px solid #88CDDD;
    }
    .stage-line_m2-2 {
      border-radius: 0 200px 200px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
    .stage-line_m2-3 {
      border-top: 2px solid #88CDDD;
    }
    // line 3
    .stage-line_m3-1 {
      margin-left: -10px;
      margin-top: 1505px;
    }
    .stage-line_m3-2 {
      margin-left: 50%;
      margin-top: 1505px;
    }
    .stage-line_m3-3 {
      margin-left: 0%;
      margin-top: 1720px;
    }
    .stage-line_m3-4 {
      margin-left: calc(50% + 10px);
      margin-top: 1935px;
    }
    .stage-line_m3-1 {
      border-top: 2px solid #88CDDD;
    }
    .stage-line_m3-2 {
      border-radius: 0 200px 200px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
    .stage-line_m3-3{
      border-radius: 200px 0 0 200px;
      border-top: 2px solid #88CDDD;
      border-right: none;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
    }
    .stage-line_m3-4 {
      border-top: 2px solid #88CDDD;
    }
    // line 4
    .stage-line_m4-1 {
      margin-left: -10px;
      margin-top: 2245px;
    }
    .stage-line_m4-2 {
      margin-left: 50%;
      margin-top: 2245px;
    }
    .stage-line_m4-3 {
      margin-left: 0%;
      height: 300px;
      margin-top: 2460px;
    }
    .stage-line_m4-4 {
      margin-left: calc(50% + 10px);
      margin-top: 2760px;
    }
    .stage-line_m4-1 {
      border-top: 2px solid #88CDDD;
    }
    .stage-line_m4-2 {
      border-radius: 0 200px 200px 0;
      border-top: 2px solid #88CDDD;
      border-right: 2px solid #88CDDD;
      border-bottom: 2px solid #88CDDD;
      border-left: none;
    }
    .stage-line_m4-3{
      border-radius: 200px 0 0 200px;
      border-top: 2px solid #88CDDD;
      border-right: none;
      border-bottom: 2px solid #88CDDD;
      border-left: 2px solid #88CDDD;
    }
    .stage-line_m4-4 {
      border-top: 2px solid #88CDDD;
    }
    // line 5
    .stage-line_m5-1 {
      border-top: 2px solid #88CDDD;
      margin-left: -10px;
      margin-top: 3015px;
    }
    .stage-line_m5-2 {
      border-top: 2px solid #88CDDD;
      margin-left: calc(50% + 10px);
      margin-top: 3015px;
    }
  }
}