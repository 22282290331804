
/*   -=========== Buttons ===========-   */

.btn{
  display: inline-block;
  width: 248px;
  height: 56px;
  border-radius: 32px;
  transform: translate(0, 0);
  white-space: nowrap;
  cursor: pointer;
  font-family: 'ProximaNova_Regular', sans-serif;
  font-weight: 400;
  margin-bottom: 8px;
  margin-right: 8px;
  transition: .3s;

  &:last-of-type{
    margin-right: 0px;
  }

  span{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  &_solid-blue,
  &_solid-blue_hover:hover{
    color: white;
    background-color: #5abad2;
    border: 2px solid #5abad2;
  }
  &_outline-blue,
  &_outline-blue_hover:hover{
    color: #5abad2;
    background-color: rgba(255,255,255,0);
    border: 2px solid #5abad2;
  }
  &_solid-white,
  &_solid-white_hover:hover{
    color: #5abad2;
    background-color: white;
    border: 2px solid white;
  }
  &_outline-white,
  &_outline-white_hover:hover{
    color: white;
    background-color: transparent;
    border: 2px solid white;
  }
}
@media(max-width: 568px) {
  .btn{
    width: 200px;
    height: 44px;
    border-radius: 20px;
    font-size: 1.4rem;
    line-height: 0.5;
  }
}

.btn_md {
  width: 132px;
  height: 32px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  transform: translate(0, 20%);
}


.btn_report-sample {
  width: 160px;
  border: 2px solid rgba(159, 219, 237, 0.51);
  background: #fff;
  margin-left: 60px;
  margin-bottom: 0;
  margin-top: 0 !important;
  vertical-align: middle;
  transition: .6s;

  @media $mobile {
    margin-left: 38px;
  }

  &__button {
    position: absolute;
    width: 90px !important;
    margin-left: -80px;
    border-color: 2px solid #5abad2;
    background-color: #5abad2;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .13);
    transition: 0.45s;
    z-index: 2;
    margin-top: 0 !important;
  }
  &__icon {
    background-image: url('/i/icon-download_white.svg');
    position: absolute;
    width: 34px;
    height: 30px;
    margin-top: 0 !important;
    margin-left: -80px;
    z-index: 3;
    transition: .55s;

  }
  &__text {
    margin-top: 0 !important;
    margin-left: 16px;
    color: #666666;
    transition: .3s;
  }
}
.btn_report-sample:hover {

  .btn_report-sample__button {
    width: 160px !important;
    margin-left: 0px !important;
    box-shadow: none;
    border: 2px solid #5abad2 !important;
    background-color: #ffffff;
  }
  .btn_report-sample__icon {
    background-image: url('/i/icon-download_blue.svg');
    margin-left: 0px;
  }
  .btn_report-sample__icon {
    color: transparent;
  }
}


// instagram buttons

.insta-btn-1,
.insta-btn-2,
.insta-btn-3,
.insta-btn-4 {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: all .25s ease;
  background: transparent;
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 15px;
  max-width: calc(100vw - 40px);
  text-overflow: ellipsis;

  @media only screen and (max-width: 480px) {
    max-width: calc(100vw - 20px);
  }
}

a.insta-btn-1 {
  border: 2px solid $color-blue;
  background-color: $color-blue;
  color: $color-white;

  &:hover {
    border: 2px solid #55b3c9;
    background-color: #55b3c9;
    color: $color-white;
  }
}

a.insta-btn-2 {
  background-color: transparent;
  border: 2px solid rgba($color-blue, .75);
  color: #4c96ab;

  &:hover {
    border: 2px solid #55b3c9;
    color: #52abc1;
  }
}

a.insta-btn-3 {
  background-color: transparent;
  border: 2px solid #c4c4c4;
  color: #666666;

  &:hover {
    border: 2px solid #a4a4a4;
    color: #555555;
  }
}

