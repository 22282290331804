
/*   -=========== Layout ===========-   */

// floats
.fl     { float: left; }
.fr     { float: right; }
.no-fl  { float: none; }

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }
  .r-main {
    float: right;
    width: 49%;
  }
}
@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}

// containers
.group, .nofloat {
  clearfix();
}
.no-overflow {
  overflow: hidden;
}
.flex-group {
  display: flex;

  &.justify {
    justify-content: space-between;
  }
}
.wrapper {
  box-sizing: border-box;

  @media only screen and (min-width: $wrapper-width + 20px) {
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: $wrapper-width + 19px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

// margins
.m3 { margin-bottom: 3px; }
.m7 { margin-bottom: 7px; }
for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}

// position
.relative     { position: relative; }
.static       { position: static !important; }

// display
.inline       { display: inline; }
.inline-block { display: inline-block; }
.block        { display: block; }
.hidden       { display: none !important; }

// devices display
@media $handheld    { .only-desktop { display: none !important; }}
@media $not-mobile  { .only-mobile  { display: none !important; }}
@media $desktop     { .not-desktop  { display: none !important; }} // mobile + tablet
@media $mobile      { .not-mobile   { display: none !important; }} // desktop + tablet