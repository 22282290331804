
/*   -============== Icons ==============-   */

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-coins {
  background: url('/i/icon-problem_coins.svg');
  width: 50px;
  height: 60px;
}
.icon-face {
  background: url('/i/icon-problem_face.svg');
  width: 57px;
  height: 61px;
}
.icon-money {
  background: url('/i/icon-problem_money.svg');
  width: 57px;
  height: 59px;
}
.icon-phone-24 {
  background: url('/i/icon-problem_phone-24.png');
  width: 52px;
  height: 58px;
}
.icon-rocket {
  background: url('/i/icon-problem_rocket.svg');
  width: 61px;
  height: 61px;
}
.icon-turnover {
  background: url('/i/icon-problem_turnover.svg');
  width: 59px;
  height: 61px;
}
.icon-analysis {
  background: url('/i/icon-stage_analysis.svg');
  width: 55px;
  height: 76px;
}
.icon-assessment {
  background: url('/i/icon-stage_assessment.svg');
  width: 80px;
  height: 68px;
}
.icon-agreement {
  background: url('/i/icon-stage_agreement.svg');
  width: 66px;
  height: 67px;
}
.icon-audit {
  background: url('/i/icon-stage_audit.svg');
  width: 77px;
  height: 68px;
}
.icon-checklist {
  background: url('/i/icon-stage_checklist.svg');
  width: 51px;
  height: 68px;
}
.icon-development {
  background: url('/i/icon-stage_development.svg');
  width: 64px;
  height: 68px;
}
.icon-document-brief{
  background: url('/i/icon-stage_document-brief.svg');
  width: 66px;
  height: 68px;
}
.icon-glass {
  background: url('/i/icon-stage_glass.svg');
  width: 57px;
  height: 68px;
}
.icon-manager {
  background: url('/i/icon-stage_manager.svg');
  width: 64px;
  height: 69px;
}
.icon-standards {
  background: url('/i/icon-stage_standards.svg');
  width: 61px;
  height: 68px;
}
.icon-two-groups {
  background: url('/i/icon-stage_two-groups.svg');
  width: 76px;
  height: 67px;
}
.icon-success {
  background: url('/i/icon-success.svg');
  width: 22px;
  height: 16px;
  margin-left: 2px;
}
.icon-download {
  width: 34px;
  height: 30px;
}
.icon-facebook {
  background: url('/i/icon-facebook.svg');
  width: 32px;
  height: 32px;
  display: inline-block !important;
  margin-right: 10px;
}
.icon-instagram {
  background: url('/i/icon-instagram.svg');
  width: 32px;
  height: 32px;
  display: inline-block !important;
  margin-right: 10px;
}
.icon-time {
  background: url('/i/icon-time.svg');
  width: 18px;
  height: 18px;
}
.icon-pages {
  background: url('/i/icon-pages.svg');
  width: 21px;
  height: 18px;
}
.icon-map-marker {
  background: url('/i/icon-map-marker.svg');
  width: 14px;
  height: 20px;
}