#testimonials {
  font-family: 'ProximaNova_Regular', sans-serif;
  font-size: 1.8rem;
  background-color: #F8F8F8;
  color: #5ABAD2;
  padding-bottom: 80px;

  .section-title_wrapper{
    display: block;
    height: 180px;
  }
  .section-title{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.8rem;
    letter-spacing: 1px;
    position: absolute;
    display: inline-block;
    transform: translate(-50%, 0%);
    left: 50%;
    margin-top: 60px;
  }
  .wrapper{
    max-width: 1420px;
  }
  .cards-slider{
    display: block;
    width: 100%;
    max-width: 100%;
    text-align: center;
    vertical-align: top;
    .slick-slide {
      outline: none;
    }
    .slick-prev, .slick-next {
      width: 30px;
      height: 30px;
    }
    .slick-prev {
      left: -35px;
    }
    .slick-next {
      right: -35px;
    }
    .slick-prev:before, .slick-next:before {
      color: transparent;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      display: inline-block;
      content: '';
    }
    .slick-prev:before{
      margin-top: -10px;
      margin-left: -4px;
      transform: rotate(45deg);
      border-left: 3px solid #5abad2;
      border-bottom: 3px solid #5abad2;
    }
    .slick-next:before {
      margin-top: -10px;
      margin-left: -16px;
      transform: rotate(45deg);
      border-top: 3px solid #5abad2;
      border-right: 3px solid #5abad2;
      vertical-align: middle;
    }

    @media only screen and (max-width: 1520px) and (min-width: 1280px){
      .slick-prev {
        /* left: 50px; */
        z-index: 10;
      }
      .slick-next {
        /* right: 50px; */
        z-index: 10;
      }
    }


    @media only screen and (max-width: $wrapper-width + 40px) {
      width: calc(100% - 100px);
      margin-left: auto;
      margin-right: auto;
    }
    @media only screen and (max-width: 480px) {
      width: calc(100% - 40px);

      .slick-prev {
        left: -25px;
      }
      .slick-next {
        right: -25px;
      }
    }
  }
  .card {
    display: inline-block;
    background-color: #fff;
    border-bottom: 2px solid #5abad2;
    max-width: 455px;
    min-height: 550px;
    box-sizing: border-box;
    text-align: center;
    padding: 2em;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 4px;
    vertical-align: top;
    width: 30%;

    &__link {
      display: inline-block;
      padding: 12px;
      border: 2px solid #5abad2;
      border-radius: 50%;
      width: 128px;
      height: 128px;
      box-sizing: content-box;
      margin-top: 1em;
      margin-bottom: 3em;
    }
    &__img {
      display: inline-block;
      border-radius: 50%;
      min-width: 128px;
      min-height: 128px;
      width: 128px;
      height: 128px;
      box-sizing: content-box;
    }
    &__quote{
      font-family: "ProximaNova_Light", Sans-Serif;
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 1.5;
      color: #666;
      text-align: left;
      padding-bottom: 4em;
      vertical-align: top;
      cursor: pointer;
    }
    &__quote-spoiler {
      display: inline-block;
    }
    &__quote-more{
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align center;
      color: #5abad2;
      margin-top: 8px;
      border-bottom: 1px dotted #5abad2;
      width: 96px;
    }
    &__quote-detail{
      display: none;
    }
    &__person,
    a{
      font-family: "ProximaNova_Regular", Sans-Serif;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 1.5;
      color: #5abad2;
      text-align: right;
      text-transform: uppercase;
      text-decoration: none;
    }
    &__role{
      font-family: "ProximaNova_Bold", Sans-Serif;
      font-weight: 600;
      font-size: 1.6rem;
      color: #666;
      text-align: right;
    }
  }

  @media (max-width: 1280px) {
    .card {
      padding: 1.5em;
      margin-left: .75em;
      margin-right: .75em;
      min-height: 510px;

      &__link {
        padding: 10px;
        width: 108px;
        height: 108px;
      }
      &__img {
        width: 108px;
        height: 108px;
        min-width: 108px;
        min-height: 108px;
      }
      &__quote {
        font-size: 1.6rem;
        line-height: 1.3;

        &-more{
          width: 86px;
        }
      }
      &__person {
        font-size: 1.6rem;
        line-height: 1.4;
      }
      &__role {
        font-size: 1.5rem;
      }
    }
  }
  @media (max-width: 1024px){
    .card {
      padding: 1.0em;
      margin-left: .5em;
      margin-right: .5em;
      min-height: 460px;

      &__link {
        padding: 8px;
        width: 96px;
        height: 96px;
      }
      &__img {
        width: 96px;
        height: 96px;
        min-width: 96px;
        min-height: 96px;
      }
      &__quote{
        font-size: 1.4rem;
        line-height: 1.2;

        &-more{
          width: 75px;
        }
      }
      &__person {
        font-size: 1.4rem;
        line-height: 1.3;
      }
      &__role {
        font-size: 1.4rem;
      }
    }
  }
  @media (max-width: 666px){
    //display: none; // пока выключаем для sm, sx
  }
}
