
/*   -=========== Sticky responsive header ===========-   */


$desktop_header_height = 120px;
$mobile_header_height = 64px;

/* ---- mobile first ------ */
.cd-auto-hide-header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: $mobile_header_height;
  background-color: $color-white;
  box-shadow: inset 0 -1px 0 0px #c6eaf9;

  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;

  &::after {
    clear: both;
    content: "";
    display: block;
  }
  &.is-hidden {
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
body{
  margin-top: $mobile_header_height;
}


.cd-auto-hide-header {

  .logo-wrapper,
  .header-phones-wrapper,
  .order-btn-wrapper,
  .callback-btn-wrapper,
  .nav-trigger {
    position: absolute;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .logo-wrapper {
    display: inline-block;
  }
  .header-phones-wrapper {
    display: none;
  }
  .order-btn-wrapper {
    z-index: 10;
    display: none;
  }
  .callback-btn-wrapper {
    display: inline-block;
  }



  .logo-wrapper {
    //left: 50%;
    //margin-left: -55px;
    left: calc(10% + 12px);
    margin-left: 0px;
    width 110px;
    height 32px;

    &a,
    &img {
      display: block;
    }
  }
  .callback-btn-wrapper {
    right: 15px;
    text-align: right;
    width: calc(100% - 30px);

    .icon{
      display: inline-block;
      margin-right: 1%;
    }

    .phone-number-md {
      font-size: 12px;
      vertical-align: 30%;
      height: 32px;
      line-height: 32px;
      margin-right: 12px;
    }
    .icon-callback{
      width: 20px;
      height: 20px;
      padding: 6px;
      background-image: url('/i/icon-phone.svg');
      box-sizing: content-box;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .icon-map-marker{
      width: 14px;
      height: 20px;
      padding: 6px 10px;
      background-image: url('/i/icon-map-marker.svg');
      box-sizing: content-box;
      background-size: 14px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .icon-message{
      display: none;
      width: 19px;
      height: 13px;
      padding: 10px 7px;
      background-image: url('/i/icon-message.svg');
      box-sizing: content-box;
      background-size: 19px 13px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}


/* ---------- desktop styles ------------ */
@media only screen and (min-width: 1024px) {

  .cd-auto-hide-header{
    height: $desktop_header_height;

    .logo-wrapper {
      left: 15px;
      left: 5%;
      margin: 0;
      width: 242px;
      height: 72px;
    }
    .order-btn-wrapper{
      display: inline-block;
      right: 15px;
      right: 5%;
    }
    .header-phones-wrapper {
      display inline-block;
      color: $color-gray;
      right: calc(5% + 246px + 40px);
      margin-top: -20px;
      z-index: 10;

      a{ color: $color-gray; }
      a:hover,
      a:focus{
        color: $color-blue;
      }
      .header-phones,
      .header-phones-devider,
      .header-callback {
        display: inline-block;
      }

      .header-phones{
      }
      .header-phones-devider{
      }
      .header-callback{
        font-size: 17px;
        padding: 4px 2px;
        border-bottom: 1px dotted $color-gray;
      }
      .header-callback:hover{
        color: $color-blue !important;
        border-bottom: 1px dotted $color-blue !important;
      }
    }
    .callback-btn-wrapper{
      display: none;
    }
  }
  body{
    margin-top: $desktop_header_height;
  }
}

.cd-auto-hide-header {

  .nav-trigger {
    /* vertically align its content */
    display: table;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $color-gray;
    font-weight: bold;
    left: 0;
  }
  .nav-trigger span {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
  }
  .nav-trigger em,
  .nav-trigger em::after,
  .nav-trigger em::before {
    /* this is the menu icon */
    display: block;
    position: relative;
    height: 2px;
    width: 22px;
    background-color: $color-blue;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .nav-trigger em {
    /* this is the menu central line */
    margin: 6px auto 14px;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
  }
  .nav-trigger em::before,
  .nav-trigger em::after {
    position: absolute;
    content: '';
    left: 0;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
  }
  .nav-trigger em::before {
    /* this is the menu icon top line */
    -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
            transform: translateY(-6px);
  }
  .nav-trigger em::after {
    /* this is the menu icon bottom line */
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
  }
}

@media only screen and (min-width: 1024px) {
  .cd-auto-hide-header .nav-trigger {
    display: none;
  }
}


.cd-auto-hide-header.nav-open{

  .nav-trigger{
    em { /* transform menu icon into a 'X' icon */
      background-color: rgba(255, 255, 255, 0);
    }
    em::before { /* rotate top line */
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    em::after { /* rotate bottom line */
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
    }
  }
}


// mobile first
.cd-primary-nav {
  display: inline-block;
  width 60%;
  height: 100%;
  margin: 0 auto;
}
.cd-primary-nav > ul {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: none;
  vertical-align: middle;
  text-align left;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.2)
}
.cd-primary-nav > ul a {
  /* target primary-nav links */
  display: block;
  height: 50px;
  line-height: 50px;
  padding-left: 5%;
  font-size: 1.8rem;
  border-top: 1px solid #f2f2f2;
  text-decoration: none;
  border-left: 6px solid transparent;
  color: $color-blue;
  font-family: 'ProximaNova_Regular', Sans-Serif;
  font-weight: 400;
  transition: 0.3s;
}
.cd-primary-nav > ul a:hover,
.cd-primary-nav > ul a.active {
  border-left: 6px solid $color-blue;
}

// desktop view
@media only screen and (min-width: 1024px) {
  .cd-primary-nav {
    /* vertically align its content */
    display: table;
  }
  .cd-primary-nav > ul {
    /* vertically align inside parent element */
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    /* reset mobile style */
    position: relative;
    width: auto;
    height: 120px;
    top: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .cd-primary-nav > ul::after {
    clear: both;
    content: "";
    display: block;
  }
  .cd-primary-nav > ul li {
    display: inline-block;
    margin: 0;
  }
  .cd-primary-nav > ul li:first-of-type {
    margin-left: 0;
  }
  .cd-primary-nav > ul li:last-of-type {
    margin-right: 0;
  }
  .cd-primary-nav > ul a {
    /* reset mobile style */
    height: auto;
    line-height: normal;
    padding: 8px 0 24px 0;
    border: none;
    border-left: none;
    border-bottom: 6px solid transparent;
  }
  @media(max-width: 1280px){
    .cd-primary-nav > ul a {
      padding: 8px 0 4px 0;
    }
  }

  .cd-primary-nav > ul a:hover,
  .cd-primary-nav > ul a.active {
    border-left: none;
    border-bottom: 6px solid $color-blue;
  }
}

.nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
  /* show primary nav - mobile only :target is used to show navigation on no-js devices */
  display: block;
}
@media only screen and (min-width: 1024px) {
  .nav-open .cd-primary-nav ul,
  .cd-primary-nav ul:target {
    display: flex;
  }
}

/* ------- мобильное разрешение ------- */

@media screen and (min-width: 520px){
  .icon-message{
    display: none;
  }
  .phone-number-md{
    display: inline-block;
  }
}
@media screen and (max-width: 520px){
  .callback-btn-wrapper .icon{
    margin-right: calc(5% - 7px) !important;
  }
  .phone-number-md{
    display: none;
  }
  .btn_trigger_md{
    display: none;
  }
  .icon-message{
    display: inline-block !important;
    margin-right: 0 !important;
  }
}

/* ------- панель выбора варианта на мобильных ------- */

.phones-panel-mobile{
  display: none;
  position: absolute;
  height: 140px;
  width: 100%;
  z-index: 1;
  text-align: center;
  font-weight: 600;
  margin-top: -5px;
  background-color: #F8F8F8;
  color: #5abad2;
  border-bottom: 2px solid #5abad2;
  box-shadow: 0 4px 8px 2px rgba(0,0,0,0.05),
              0 8px 8px 8px rgba(0,0,0,0.03);

  @media(max-width: 568px){
      height: 128px;
  }
  a{
    color: #5abad2;
  }
  .row1{
    display: block;
    padding-top: 20px;
    height: 64px;
    font-weight: 600;
    font-family: 'ProximaNova_Bold', Sans-Serif;
  }
  .row2{
    display: block;
  }
}



