
/*   -=========== Print ===========-   */

@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  a, a:visited {
    text-decoration: underline !important;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  select {
    background: #fff !important;
  }

  .no-print, aside, .header, .footer, .menu {
    display: none !important;
  }
}