
/* -======== Responsive classes =========- */

.xl{ display: inline-block; }
.lg{ display: none; }
.md{ display: none; }
.sm{ display: none; }
.xs{ display: none; }
.sm_xs{ display: none; }

@media(max-width: 1024px) {
  .xl{ display: none; }
  .lg{ display: inline-block; }
  .md{ display: none; }
  .sm{ display: none; }
  .xs{ display: none; }
  .sm_xs{ display: none; }
}
@media(max-width: 800px) {
  .xl{ display: none; }
  .lg{ display: none; }
  .md{ display: inline-block; }
  .sm{ display: none; }
  .xs{ display: none; }
  .sm_xs{ display: none; }
}
@media(max-width: 568px) {
  .xl{ display: none; }
  .lg{ display: none; }
  .md{ display: none; }
  .sm{ display: inline-block; }
  .xs{ display: none; }
  .sm_xs{ display: inline-block; }
}
@media(max-width: 320px) {
  .xl{ display: none; }
  .lg{ display: none; }
  .md{ display: none; }
  .sm{ display: none; }
  .xs{ display: inline-block; }
  .sm_xs{ display: inline-block; }
}

html{
  -webkit-text-size-adjust: none;
}
body{
  font-family: 'ProximaNova_Light', sans-serif;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 300;
  color: #848686;
}
.section-title{
  text-transform: uppercase;
  font-family: 'ProximaNova_Bold', sans-serif;
  font-weight: bold;
  font-size: 2.8rem;
  letter-spacing: 1.5px;

  @media(max-width: 1024px) {
    font-size: 2.6rem;
    letter-spacing: 0.9px;
  }
  @media(max-width: 768px) {
    font-size: 2.4rem;
    letter-spacing: 0.8px;
  }
  @media(max-width: 568px) {
    font-size: 2.2rem;
    letter-spacing: 0.66px;
  }
}
.v-spacer{
  display: block;
}