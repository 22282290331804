
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $color-red;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .3125em;
  line-height: 1.25em;
  color: lighten($color-gray, 5);
  border: 1px solid lighten($color-gray, 5);
  transition: all .25s ease;

  &:focus {
    color: $color-gray;
    border-color: $color-gray;
  }

  .form-field.error > & {
    border-color: $color-red;
    color: $color-red;
  }
}

.form-row {
  clearfix();

  + .form-row {
    .one-column-form & {
      margin-top: .5em;
    }

    .two-columns-form & {
      margin-top: 1.25em;
    }
  }
}

.form-label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1em;
    padding: 1px 0 3px;
    text-align: center;
    background: $color-red;
    color: $color-white;

    .two-columns-form & {
      position: absolute
      left: 0; bottom: 0;
      transform: translateY(100%);
      width: 100%;
    }
  }

  &.error {
    margin-bottom: .25em;

    .errorMessage {
      display: block;
    }
  }
}

.form-submit {
  text-align: center;

  .two-columns-form & {
    float: right;
  }
}



/* -==== Стандартная форма на сайте ====- */

.inline-form{
  background-color: #5abad2;
  padding: 64px 64px 48px 64px;
  border-radius: 16px;

  .form__wrapper{

  }
  .form__title{
    font-size: 2.8rem;
    font-weight: 600;
    font-family: 'ProximaNova_Bold', sans-serif;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.66px;
    text-align: center;
  }

  .form__input {
    display block;
    width: 100%
    padding: 6px 0px;
    font-size: 1.8rem;
    background-color: transparent;
    margin-bottom: 20px;
    border-bottom: 2px solid #fff;
    color: #fff;
    font-family: 'ProximaNova_Bold', Sans-Serif;
    font-weight: 600;
    transition: 0.45s;
  }
  .form__input::-webkit-input-placeholder {
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
  }
  .form__input:hover::-webkit-input-placeholder {
    color: #ffffff;
  }
  .form__input:focus::-webkit-input-placeholder {
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
    color: #ffffff;
  }
  .form__input:hover,
  .form__input:focus {
    font-family: 'ProximaNova_Bold', Sans-Serif;
    font-weight: 600;
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  .form__button-wrapper{
    padding-bottom: 10px;
    text-align: center;
  }
  .form__note{
    font-family: 'ProximaNova_Regular', Sans-Serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: #fff;
    max-width: 380px;
    width 100%;
    text-align: center;
  }
  .form__message{
    font-family: 'ProximaNova_Light', Sans-Serif;
    font-weight: 300;
    font-size: 2.8rem;
    color: #fff;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
  }
  .form__logo{
    display: block;
    width 110px;
    height 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  a{
    color: #fff;
  }
  .errorMessage{
    font-size: 1.4rem;
    margin-bottom: 8px;
    color: #f21526;
  }
  .caption{
    color: #fff;
  }
  .checkbox:checked + label:before {
    background: #62ff2f;
  }
}

@media(max-width: 1024px) { //lg

  .inline-form{
    &__title{
      font-size: 2.4rem;
      letter-spacing: 0.55px;
    }
    &__input{
      font-size: 1.6rem;
      margin-bottom: 16px;
    }
  }

}

@media(max-width: 568px) { //sm

  .inline-form {
    &__title {
      font-size: 2.0rem;
      letter-spacing: 0.45px;
    }
    &__input {
      font-size: 1.4rem;
      margin-bottom: 12px;
    }
  }

}


/* -==== Форма заявки на отдельной странице ====- */

.request-standalone-form{
  max-width: 510px;
  min-width: 280px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 480px){
  .request-standalone-form{
    padding: 32px 28px 32px 28px;
  }
}