main.book{

  .partners{
    display: block;
    height: 356px;
    background-color: #ffffff;
    padding-top: 72px;

    .section-title{
      color: #59bad1;
      margin-bottom: 80px;
    }
    .partners-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 15px;
      padding-left: 15px;
    }

    @media only screen and (max-width: 1024px){
      height: auto;

      .section-title {
        margin-bottom: 40px;
      }
      .partners-wrapper{
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 20px;

        .partner{
          display: flex;
          flex-basis: 25%;
          max-width: 25%;
          width: 25%;
          margin-bottom: 20px;

          img{
            width: 100%;
            height: 100%;
          }
        }
        .partner_7{
          padding-top: 10px;
          padding-bottom: 20px;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .section-title {
        margin-bottom: 20px;
      }
      .partners-wrapper {
        margin-bottom: 40px;
      }
    }
    @media only screen and (max-width: 480px){
      .partners-wrapper{
        .partner{
          flex-basis: 33%;
          max-width: 33%;
          width: 33%;
        }
        .partner_1{
          box-sizing: border-box;
          margin-right: 20px;
        }
        .partner_5{
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }

}