main.book{

  .ecommerce{
    height: 600px;
    display: flex;

    .plus{
      z-index: 100;
      position: absolute;
      left: 50%;
      margin-left: -32px;
      transform: rotate(45deg);
      margin-top: 260px;
      display: block;
      width: 64px;
      height: 64px;
      background-color: #fff;
      color: #59bad1;

      .plus__sign {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 48px;
        padding-top: 7px;
        transform: rotate(-45deg);
      }
    }

    .title_block{
      display: block;
      height: 155px;

      a{
        display inline-block;
        text-decoration: none;
        box-sizing: border-box;
        border: 2px solid rgba(0,0,0,0);
        border-radius: 6px;
        transition: 0.33s;
      }
      a:hover{
        display inline-block;
        border: 2px dotted #59bad1;
      }
    }
    .section-title{
      color: #59bad1;
      .thin{
        color: #bbb;
      }
    }
    .logo{
      width: 240px;
    }
    p{
      margin-bottom: 24px;
    }


    .block_a{
      background-color: #59bad1;
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;
      color: #fff;
      font-weight: 500;
      font-family: 'ProximaNova_Regular', Sans-Serif;

      .content{
        margin-left: auto;
        padding-left: 15px;
        margin-right: 80px;
        margin-top: 130px;
        max-width: 560px;
      }
    }
    .block_b{
      background-color: #ffffff;
      width: 50%;
      max-width: 50%;
      flex-basis: 50%;

      .content{
        margin-left: 80px;
        margin-right: auto;
        padding-right: 15px;
        margin-top: 130px;
        max-width: 560px;
      }
    }

    @media only screen and (max-width: 768px){
      flex-direction: column;
      height: auto;

      .block_a,
      .block_b{
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;

        .content{
          margin-left: 15px;
          margin-right: 15px;
          padding-right: 0;
          padding-left: 0;
          margin-top: 40px;
          margin-bottom: 40px;
          max-width: 100%;
        }
      }
      .block_a{
        .content{
          margin-bottom: 64px;
        }
      }
      .block_c{
        display: flex;

        .plus{
          width: 48px;
          height: 48px;
          margin-top: -24px;

          .plus__sign{
            font-size: 44px;
            padding-top: 2px;
          }
        }
      }
      .title_block{
        height: auto;
        padding-bottom: 40px;
      }
      .logo{
        width: 190px;
        height: 60px;
      }
      .plus{
        margin-top: 0;
        margin-bottom: auto;
      }
    }
  }

}