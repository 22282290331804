.pager li{
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  background-color: white;
  list-style: none;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.pager li a{
  text-decoration: none;
  display: block;
  padding-top: 4px;
  width: 32px;
  height: 32px;
  position: absolute;
  font-family: 'ProximaNova_Bold', Sans-Serif;
  font-weight: 600;
  border: 1px solid #eeeeee;
  color: #888;
}
.pager li.previous{
  display: none;
}
.pager li.next{
  display: none;
}
.pager li.selected{
  background-color: #5ABAD2;
}
.pager li.selected a{
  color: #ffffff;
}