main.book{

  .hashtag{
    display: block;
    background-color: #f2f2f2;
    padding-top: 64px;
    padding-bottom: 16px;

    .section-title{
      color: #59bad1;
      font-family: 'ProximaNova_Regular', Sans-Serif;
      font-weight: 400;

      strong{
        font-family: 'ProximaNova_Bold', Sans-Serif;
        font-weight: 600;
      }
    }
    .hashtag{
      font-family: 'ProximaNova_Bold', Sans-Serif;
      font-weight: 600;
      color: #59bad1;
      font-size: 85px;
      letter-spacing: 2px;
    }

    .wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .block_a{
        flex-basis: 50%;
      }
      .block_b{
        flex-basis: 50%;
        text-align: right;
      }
    }

    @media only screen and (max-width: 1023px){
      padding-top: 32px;
      padding-bottom: 24px;
      height: auto;

      .hashtag{
        font-size: 64px;
      }
      .wrapper{
        flex-direction: column;
      }
    }
    @media only screen and (max-width: 768px){
      .hashtag{
        font-size: 48px;
      }
    }
    @media only screen and (max-width: 480px){
      padding-top: 24px;

      .block_b{
        padding-top: 8px;
      }
      .hashtag{
        overflow: hidden;
        font-size: 40px;
      }
    }
  }

}