
/* -======== Check Boxes iOS style =========- */
// src:
// http://dimox.name/custom-checkboxes-and-radio-buttons-using-css-only/

// usage:
// <label for="with" class="caption">Быть или не быть</label>
// <input  id="with" type="checkbox" value="checked"  class="checkbox"  />
// <label for="with"></label>

.caption{
  display: inline-block;
  width: calc(100% - 65px);
  height: 44px;
  margin-bottom: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1.8rem;
  font-family: 'ProximaNova_Regular', Sans-Serif;
  font-weight: 400;
  padding-left: 12px;
  transition: .3s;
}
.caption:hover{
  color: #5abad2;
}
.checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 17px;
  height: 17px;
}
.checkbox + label {
  cursor: pointer;
}
.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox:not(checked) + label {
  position: relative;   /* будем позиционировать псевдочекбокс относительно label */
  padding: 0 0 0 60px;  /* оставляем слева от label место под псевдочекбокс */
}
.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: -18px;
  left: 0;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  background: #CDD1DA;
  box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
}
.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  top: -16px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 2px 5px rgba(0,0,0,.3);
  transition: all .2s; /* анимация, чтобы чекбокс переключался плавно */
}
.checkbox:checked + label:before {
  background: #5abad2;
}
.checkbox:checked + label:after {
  left: 26px;
}


@media(max-width: 1024px) {
  .caption{
    font-size: 1.6rem;
  }
}
@media(max-width: 568px) {
  .caption{
    font-size: 1.4rem;
    padding-left: 0;
  }
}