
/* -=== Маркированный список с галочками ===- */

.chain-list{
  color: #666666;
  list-style: none;
  padding-left: 0;
  letter-spacing: 0.425px;
  margin-left: 16px;
  line-height: 1;

  li{
    margin-bottom: 20px;
    padding-left: 32px;
  }
  li:before{
    position: absolute;
    display: block;
    content: '';
    width: 32px;
    height: 32px;
    margin-left: -48px;
    margin-top: 0px;
    border-radius: 50%;
    border: 2px solid #5abad2;
    background-image: url('/i/icon-success.svg');
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 8px;
    padding-left: 6px;
    background-size: 16px;
    background-position: center center;
  }
  li:after{
    border-left: 2px dotted #9fdbed;
    position: absolute;
    display: block;
    content: '';
    width: 2px;
    height: 24px;
    margin-left: -33px;
    margin-top: -4px;
  }
  li:last-of-type:after{
    height: 0;
  }
}

@media(max-width: 1024px) { //lg
  .chain-list{
    li{ font-size: 1.6rem; }
  }
}
@media(max-width: 768px) { //md
  .chain-list{
    li{ font-size: 1.8rem; }
  }
}
@media(max-width: 568px) { //sm
  .chain-list{
    li{ font-size: 1.4rem; }
  }
}
@media(max-width: 320px) { //xs
  .chain-list{
    li{ font-size: 1.4rem; }
  }
}