
.slick-slider {
  .slick-prev:before,
  .slick-next:before {
    color: transparent;
    position: absolute;
    width: 20px;
    height: 20px;
    display: inline-block;
    content: '';
  }
  .slick-prev:before {
    margin-top: 4px;
    margin-left: -32px;
    transform: rotate(45deg);
    border-left: 3px solid #5abad2;
    border-bottom: 3px solid #5abad2;
  }
  .slick-next:before {
    margin-top: 4px;
    margin-left: 8px;
    transform: rotate(45deg);
    border-top: 3px solid #5abad2;
    border-right: 3px solid #5abad2;
    vertical-align: middle;
  }
  .slick-dots {
    bottom: -32px;

    li {
      width 24px;
      margin: 0 3px;
      border-bottom: 3px solid #ccc;

      button:before {
        color: transparent;
      }
      &.slick-active {
        border-bottom: 3px solid #5abad2;
      }
    }
  }
}


#services{
  background-color: transparent;

  .section-title{
    color: #5ABAD2;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .title{
    color: #7eb9cf;
    text-transform uppercase;
    font-size: 20px;
    font-weight: bold;
    font-family: 'ProximaNova_Bold',sans-serif;
    text-align center;
    border-bottom: 6px solid #ffffff;

    @media only screen and (max-width: 800px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 568px){
      font-size: 14px;
    }
    @media only screen and (max-width: 350px){
      font-size: 12px;
    }
  }

  .price{
    color: #7eb9cf;
    font-weight: bold;
    font-family: 'ProximaNova_Bold',sans-serif;
    font-size: 2.8rem;
    padding-bottom: 7px;
    white-space: nowrap;
    text-align center;

    @media only screen and (max-width: 1280px){
      font-size: 2.4rem;
    }
  }
  .btn{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 206px;
    height: 54px;
    border-radius: 32px;
    transform: translate(0, 0);
    white-space: nowrap;
    cursor: pointer;
    font-family: 'ProximaNova_Regular', sans-serif;
    font-weight: 500;
    transition: .3s;
    color: white;
    background-color: #5abad2;
    border: 2px solid #5abad2;

    span{
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      text-align: center;
    }
    &:hover{
      background-color: #5ac2da;
      border: 2px solid #5ac2da;
    }
  }


  .services-slider-1{
    display: flex;
    margin-bottom: 40px;

    .slide{
      font-size: 18px;
      color: #666666;
      margin-right: 6px;
      outline: 0 solid transparent !important;

      .title,
      .audience,
      .description,
      .features,
      .data,
      .total{
        box-sizing: border-box;
        padding: 20px;
      }
      .title{

      }
      .audience{
        font-weight: normal;
        text-align center;
        height: 120px;
        border-bottom: 6px solid #ffffff;
      }
      .description{
        font-weight: bold;
        text-align: left;
        height: 155px;
      }
      .features{
        text-align: left;
        border-bottom: 6px solid #ffffff;
        padding-right: 10px;
        height: 590px;

        ul{
          margin-bottom: 22px;
          text-align: left;

          li{
            list-style: none;
            padding-left: 20px;
            margin-bottom: 15px;
          }
          li:before{
            position: absolute;
            content: '';
            width: 14px;
            height: 10px;
            background-size: 14px;
            margin-top: 4px;
            margin-left: -20px;
            background-image: url(/i/icon-success.svg);
          }
        }
      }
      .data{
        text-align: center;
        height: 210px;
        border-bottom: 6px solid #ffffff;
      }
      .total{
        text-align: center;
        padding: 10px;
      }
      .btn{
        width: 206px;
      }
    }
    .slide:nth-of-type(2n+1){
      background-color: #ecf6f8;
    }
    .slide:nth-of-type(2n+2){
      background-color: #ffffff;
    }

    .slide.bordered{
      box-shadow: 0 0 0 6px #5abad2 inset;

      .title{
        color: #5abad2;
        border-bottom: none;
        margin-bottom: 6px;
      }
      .audience,
      .description,
      .features,
      .data{
        border-bottom: none;
      }
    }

    @media only screen and (max-width: 1280px){

      .slide{
        font-size: 1.6rem;

        .title,
        .audience,
        .description,
        .features,
        .data,
        .total{
          padding: 15px;
        }

        .audience {
          height: 105px;
        }
        .description{
          height: 110px;
        }
        .features{
          height: 510px;
          padding-right: 5px;
        }
        .total{
          padding: 7px;
        }
        .btn{
          width: 180px;
        }
      }
    }

    @media only screen and (max-width: 1024px){
      .slick-next{
        right: 35px;
        z-index: 10;
      }
      .slick-prev{
        left: 35px;
        z-index: 10;
      }
    }

    @media only screen and (max-width: 568px){
      width: 250px;
      margin-left: auto;
      margin-right: auto;

      .slide {
        .audience,
        .description,
        .features,
        .data {
          height: auto;
        }
      }
      .slick-next{
        right: 0px;
      }
      .slick-prev{
        left: 0px;
      }
    }
  }

  .hint-text{
    padding: 5px 5px;
    text-align: right;
    @media only screen and (max-width: 1024px) {
      padding: 20px 5px;
    }
  }
  .table2,
  .table3{
    display: flex;
  }
  .fixed-col-table-2,
  .fixed-col-table-3{
    background-color: #ecf6f8;
    width: 334px;
    margin-right: 6px;
    margin-bottom: 40px;

    @media only screen and (max-width: 1280px){
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 480px){
      width: 240px;
    }
    @media only screen and (max-width: 375px){
      width: 180px;
    }
  }
  .title{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .row{
    height: 54px;
    padding: 0 10px 10px 10px;
  }
  .row2{
    height: 72px;
  }
  .row3{
    height: 96px;
  }
  .table-caption {
    margin-top: 28px;
    font-weight: bold;
    color: #5abad2;
    text-align: right;
    padding-right: 20px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 480px){
    .row{
      height: 72px;
    }
    .row2{
      height: 88px;
    }
    .row3{
      height: 130px;
    }
  }

  @media only screen and (max-width: 375px){
    .row{
      height: 80px;
    }
    .row2 {
      height: 120px;
    }
    .row3 {
      height: 160px;
    }
  }

  .checked{
    display: block;
    width: 14px;
    height: 18px;
    margin-left auto;
    margin-right auto;
    background-size: 14px;
    background-image: url(/i/icon-success.svg);
    background-repeat: no-repeat;
    background-position-y: bottom;
  }
  .total{
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .services-slider-2,
  .services-slider-3 {
    width: calc(100% - 340px);
    margin-bottom: 40px;

    .slide {
      font-size: 1.8rem;
      color: #666666;
      margin-right: 6px;
      outline: 0 solid transparent !important;

      .row{
        text-align: center;
      }
      .btn{
        line-height: 0.75;
        width: 120px;
      }
    }
    .slide:nth-of-type(2n+1){
      background-color: #ecf6f8;
    }
    .slide:nth-of-type(2n+2){
      background-color: #ffffff;
    }

    .slide.bordered{
      box-shadow: 0 0 0 6px #5abad2 inset;

      .title{
        color: #5abad2;
        border-bottom: none;
        margin-bottom: 6px;
      }
    }


    @media only screen and (max-width: 1280px){
      .slide{
        font-size: 1.6rem;
      }
    }
    @media only screen and (max-width: 1024px){
      .slick-next{
        right: 35px;
        z-index: 10;
      }
      .slick-prev{
        left: 35px;
        z-index: 10;
      }
    }
    @media only screen and (max-width: 568px){

    }
    @media only screen and (max-width: 480px){
      width: calc(100% - 240px);
    }
    @media only screen and (max-width: 375px){
      width: calc(100% - 180px);

      .slide .btn{
        width: 94px;
      }
    }
  }


// slick slider hacks
  .slick-slider{

    @media only screen and (max-width: 1024px){
      .slick-dots{
        li:nth-last-child(1){
          display: none;
        }
        li:nth-last-child(2){
          display: none;
        }
      }
    }
    @media only screen and (max-width: 800px){
      .slick-dots{
        li:nth-last-child(1){
          display: none;
        }
        li:nth-last-child(2){
          display: inline-block;
        }
      }
    }
    @media only screen and (max-width: 568px){
      .slick-dots{
        li{
          width: 16px;
        }
        li:nth-last-child(1){
          display: inline-block;
        }
        li:nth-last-child(2){
          display: inline-block;
        }
      }
    }
  }
}


// Ссылка на вакансии jooble после блока тарифов

.job-link{
  font-weight: 600;
  font-size: 24px;
  color: #5ABAD2;
  border-radius: 48px;
  padding: 20px 28px;
  border: 3px solid #5ABAD2;
  text-decoration: none;
  transition: .4s;
}
.job-link:hover{
  background-color: #5ABAD2;
  color: #ffffff;
}
@media only screen and (max-width: 768px) {
  .job-link{
    font-size: 24px;
  }
}
@media only screen and (max-width: 480px){
  .job-link{
    font-size: 18px;
  }
}
@media only screen and (max-width: 375px){
  .job-link{
    font-size: 18px;
    padding: 0;
    border: none;
    text-decoration: underline;
  }
}

.services-info-list-slide-line {
  display: block;
  margin: 0 auto;
  width: 100%; height: 4px;
  margin-top: 40px;
  max-width: 930px;
  background: $color-blue;
}

.services-info-list-slide-title {
  padding-top: 60px;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}

.services-info-list-slider {
  white-space: nowrap;
  padding-bottom: 100px;
  padding-top: 60px;
  margin-bottom: 20px !important;
  position: relative;

  .slick-list {
    padding: 0 !important;
  }

  .item {
    color: $color-blue;
    font-size: 2.2rem;
    font-family: 'ProximaNova_Regular', sans-serif;
    float: none;
    display: inline-block;
    vertical-align: middle;
    outline: none;
  }

  .slick-current {
    font-size: 3rem;
    font-weight: bold;
    font-family: 'ProximaNova_Bold', sans-serif;
    text-align: center;

    @media only screen and (max-width: 480px) {
      font-size: 2rem;
    }
  }

  .slick-current + .item {
    text-align: right;
  }

  .item:not(.slick-current) {
    cursor: pointer;
  }

  .slick-arrow {
    top: 78px;
  }
  .slick-arrow:before {
    margin-top: -14px;
  }
  .slick-prev:before,
  .slick-next:before {
    width: 26px;
    height: 26px;
  }
  .slick-prev:before {
    border-left: 4px solid #5abad2;
    border-bottom: 4px solid #5abad2;
  }
  .slick-next:before {
    border-top: 4px solid #5abad2;
    border-right: 4px solid #5abad2;
  }

  .slick-dots {
    width: auto;
    line-height: 0;
    margin: 0 auto 30px;
    left: 50%; bottom: 0;
    transform: translateX(-50%);

    li {
      display: inline-block !important;
    }

    &:before {
      content: '';
      display: block;
      width: calc(100% - 2px); height: 1px;
      background: $color-blue;
      position: absolute;
      left: 1px; top: 50%;
      transform: translateY(-50%);
    }

    li {
      position: relative;
      z-index: 2;
      border: none !important;
      width: auto; height: auto;
      margin: 0;
    }

    li + li {
      margin-left: 65px;

      @media only screen and (max-width: 480px) {
        margin-left: 25px;
      }
    }

    button {
      width: 17px; height: 17px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid $color-blue;
      background: #fff;
    }

    .slick-active button {
      background: $color-blue;
    }
  }
}

.services-info-slider {
  margin-bottom: 90px;

  @media only screen and (max-width: 480px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .item {
    outline: none;
    background: #ecf6f8;
    padding: 65px 98px 45px;
    color: #656565;

    @media only screen and (max-width: 800px) {
      padding-left: 45px;
      padding-right: 45px;
    }

    @media only screen and (max-width: 480px) {
      padding-top: 35px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .item__caption {
    color: $color-blue;
    font-size: 2.2rem;
    font-weight: bold;
    font-family: 'ProximaNova_Bold', sans-serif;
    text-transform: uppercase;
    margin-bottom: 1em;

    @media only screen and (max-width: 800px) {
      font-size: 2.0rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  .item__row {
    display: flex;
    margin-top: 3em;

    @media only screen and (max-width: 480px) {
      display: block;
      margin-top: 2em;
    }
  }

  .item__column {
    flex: 1;
  }

  .item__column + .item__column {
    margin-left: 30px;

    @media only screen and (max-width: 480px) {
      margin-left: 0;
      margin-top: 1em;
    }
  }


  .item__subcaption {
    font-size: 2.4rem;
    font-weight: bold;
    font-family: 'ProximaNova_Bold', sans-serif;
    margin-bottom: 1em;

    @media only screen and (max-width: 800px) {
      font-size: 2.2rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 1.6rem;
    }
  }

  .item__list {
    font-size: 1.8rem;
    margin-bottom: 3em;

    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 1.4rem;
    }

    li {
      list-style: none;
      padding-left: 28px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 12px; height: @width;
        background: $color-blue;
        border-radius: 50%;
        position: absolute;
        left: 0; top: .25em;
      }
    }

    li + li {
      margin-top: 1em;
    }
  }

  .item__text {
    font-size: 1.8rem;

    @media only screen and (max-width: 800px) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 1.4rem;
    }
  }

  .item__button {
    text-align: center;
    margin-top: 3em;

    .btn {
      width: auto;
      padding: 0 28px;
      font-size: 1.6rem;
    }
  }
}
