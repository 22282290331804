#subscribe {
  background-color: #F8F8F8;

  .section-title {
    display inline-block;
    white-space: nowrap;
    color: #5abad2;
    margin-right: 16px;
  }
  .thin {
    font-family: 'ProximaNova_Light', Sans-Serif;
    font-weight: 300;
  }
  .section-title-center{
    text-align: center;
    width: 100%;
    padding-top: 50px;
  }

  #subscribe-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .email {
    display block;
    margin-left: 10%;
    width: 100%;

    &__input {
      display block;
      width: 100%
      padding: 16px 12px;
      background-color: transparent;
      border-bottom: 2px solid #5abad2;
      color: #5abad2;
      font-family: 'ProximaNova_Bold', Sans-Serif;
      font-weight: 600;
      transition: 0.45s;
    }

    &__input::-webkit-input-placeholder {
      font-family: 'ProximaNova_Regular', Sans-Serif;
      font-weight: 400;
      color: #888888;
    }
    /*
    &__input:hover::-webkit-input-placeholder {
      color: #5abad2;
    }
    */
    &__input:focus::-webkit-input-placeholder {
      font-family: 'ProximaNova_Regular', Sans-Serif;
      font-weight: 400;
      color: #5abad2;
    }
  }

  .email__thick-line{
    border-top: 3px solid #5abad2;
    display: block !important
    height: 3px;
    width: 0%;
    transition: 0.66s;
  }
  .email_focused{
    .email__thick-line{
      width: 100%;
    }
  }

  .btn_subscribe {
    min-width: 248px;
    margin-left: 16px;
  }

  .section-title,
  .email,
  .btn_subscribe {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #FormSubscribe_email_em_{
    position: absolute;
  }


  @media (max-width: 1280px) { //xl
  }
  @media (max-width: 1024px) { //lg

    .wrapper {
      margin-left: 10%;
      margin-right: 10%;
    }
    .email {
      margin-left: 0;
    }
    .btn_subscribe {
      min-width: 164px;
    }
  }
  @media (max-width: 800px) { //md

    #subscribe-form {
      flex-wrap: wrap;
      padding-bottom: 80px;
    }
    .btn_subscribe {
      display inline-block;
      margin-left: auto;
      margin-right: auto;
    }
    #FormSubscribe_email_em_{
      position: inherit;
    }
  }
  @media (max-width: 568px) { //sm
  }
  @media (max-width: 320px) { //xs

    .section-title{
      text-align: center;
      font-size: 2.1rem;
    }
  }

}
  #subscribe-body{
    min-height: 160px;
  }
  #subscribe-success{
    min-height: 160px;
  }
