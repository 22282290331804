#contacts{
  height: 845px;
  min-height: 845px;
  font-family: 'ProximaNova_Regular', Sans-Serif;
  font-weight: normal;

  .map{
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 845px;
    background-position-x: right;
    background-position-y: center;
  }
  .map_big{     display: block; }
  .map_small{   display: none; }

  .panel{
    position: absolute;
    width: 63.5%;
    height: 845px;
    background-color: rgba(90, 186, 210, 0.9);
    color: #fff;
  }
  .wrapper{
    margin-top: 100px;
    margin-right: 10%;
    margin-left: calc(10% + 15px);
  }
  .section-title{
    color: #fff;
    letter-spacing: 2px;

  }
  .contacts-callback{
    height: 68px;
    padding-top: 5px;
    margin-bottom: 0;

    &__button{
      width: 274px;
      position: absolute;
      z-index: 1;
      color: #5abad2;
      background-color: #fff;
      border: 2px solid #fff;
      box-shadow: 0 0 6px 0 rgba(0,0,0,.13);
    }
    &__button:hover {
      color: #ffffff;
      background-color: #5abad2;
      border: 2px solid #fff;
      box-shadow: none;
    }
    &__number{
      width: 256px;
      position: absolute;
      margin-left: 225px;
      z-index: 0;
      border: 2px solid #fff;
      color: #fff;
      font-weight: bold;
      font-family: 'ProximaNova_Bold', sans-serif;
    }
    &__number:hover {
      background-color: rgba(255, 255, 255, 0.25);
      box-shadow: none;
    }
    // https://ru.stackoverflow.com/questions/229360/Как-убрать-дефолтные-стили-в-ios
    a[href^=tel]{ // .contacts-callback__number
      color: #ffffff;
    }
  }
  .phone{
    display: block;
    padding-bottom: 20px;
    .section-title{ margin: 20px 0; }
  }
  .location{
    display: block;
    line-height: 1;
    padding-top: 20px;
    .section-title{  margin: 20px 0; }

    a, a:hover{ color: #fff; }
    a{ text-decoration: none; }
    a:hover{ text-decoration: underline; }
  }
  address{
    font-style: normal;

    p{
      line-height: 1.2;
      padding: 0;
      margin: 0;
    }
    .city{}
    .town{}
    .street{}
    .number{}
    .description{}

  }
  .social-links{
    display: block;
    padding-top: 48px;
    padding-bottom: 60px;
  }

  footer{
    display: block;

    .footer__logo{
      background-image: url('/i/logo-footer.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 190px;
      height: 60px;
    }
    .footer__titles{
      margin-top: 40px;
      text-align: right;
      float: right;
    }
    .footer__titles_small{
      font-size: 1.4rem;
      line-height: 1.3;
      margin: 0;
      padding: 0;
      text-align: right;
    }
    .footer__hr{
      margin-top: 44px;
      margin-bottom: 20px;
      border-top: none;
      background: #fff;
    }
    .footer__link-copyright{
      color: #fff;
      text-decoration: none;
      vertical-align: top;
      height: 32px;
    }
    .footer__link-privacy-police{
      color: #fff;
    }
    .footer__shogo-logo{
      vertical-align: top;
      margin-top: -8px;
      margin-right: 12px;
    }
    .footer__link-year{
      vertical-align: top;
    }
    //.footer__link-copyright{    background-color: #004f00; }
    //.footer__shogo-logo {       background-color: #ff8f26; }
    //.footer__text-copyright{    background-color: rgba(255, 255, 0, 0.33); }
    //.footer__link-year{         background-color: #ff2c99; }
  }


  @media(max-width: 1024px) { //lg
    font-size: 1.6rem;

    .map{
      display block;
      position: relative;
      height 200px;
    }
    .map_big{     display: none; }
    .map_small{   display: block; }

    .panel{
      display: block;
      position relative;
      width: 100%;
      height: 660px;
    }
    .wrapper{
      padding-top: 40px;
      margin-top: 0;
      margin-left: 10%;
      margin-right: 10%;
    }
    .social-links{
      display: block;
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }
  @media(max-width: 768px) { //md
    .contacts-callback{

      &__button{
        width: 240px;
        font-size: 1.6rem;
      }
      &__button:hover {
      }
      &__number{
        width: 220px;
        font-size: 1.6rem;
        margin-left: 195px;
      }
      &__number:hover {

      }
    }
  }
  @media(max-width: 568px) { //sm
    font-size: 1.4rem;

    .section-title{
      text-align: center;
      letter-spacing: 0.66px;
    }
    .panel {
      height: 760px;
    }
    address{
      text-align: center;
    }
    .location{
      text-align: center;
    }
    .social-links{
      text-align: center;
      padding-top: 32px;
      padding-bottom: 64px;
    }

    .contacts-callback{

      &__button{
        display: block;
        font-size: 1.6rem;
        margin-top: 48px;
        width: 240px;
        left: 50%;
        margin-left -120px;
      }
      &__button:hover {
      }
      &__number{
        display: block;
        background-color: transparent;
        border: 2px solid transparent;
        width: 240px;
        margin-top: 0;
        left: 50%;
        margin-left -120px;
      }
      &__number:hover {
        background-color: transparent;
        border: 2px solid transparent;
      }

    }

    footer {
      .footer__logo{
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
      .footer__hr {
        margin-top: 66px;
      }
      .footer__titles_small{
        text-align center;
        font-size: 1.4rem;
      }
      .footer__titles{
        text-align center;
      }
      .footer__shogo-logo{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
      }
    }
  }
  @media(max-width: 320px) { //xs

  }
}