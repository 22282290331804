
/*   -=========== Structure ===========-   */

.svg-sprite {
  display: none;
}

// Base

body {
  /* display: table; */
  width: 100%;
  overflow-x: hidden;
}

main {
  overflow: hidden;
}

#structure {
  /* display: table-row; */
  /* height: 100%; */
  margin: auto;
  position: relative;
}



//#welcome
._markup{
  .wrapper_1{   background-color: rgba(47, 165, 255, 0.33); }
  .wrapper_2{   background-color: rgba(52, 227, 66, 0.4);   }
  .wrapper_3{   background-color: rgba(227,106,153,0.4);    }
  .v-spacer{    background-color: rgba(255, 69, 0, 0.16);   }
}

//#cases
._markup{
  .wrapper{               background-color: rgba(34, 255, 197, 0.66);  }
  .wrapper_1{             background-color: rgba(86, 213, 255, 0.66); }
  .wrapper_2{             background-color: rgba(255, 120, 255, 0.66); }
  .section-title_wrapper{ background-color: rgba(255, 165, 0, 0.33); }
  .case-items_wrapper{    background-color: rgba(255, 46, 127, 0.33); }
  .case-item__icon {      background-color: yellow; }
  .case-item__caption {   background-color: orange; }
}

//#reasons
._markup{
  .reason-lines_wrapper { background: rgba(255, 255, 0, 0.25); }
  .reason-line_1 {        background-color: orange; }
  .reason-line_2{         background-color: #ff6109; }
  .reason-line_3{         background-color: #ff4712; }
  .reason-line_4{         background-color: #ff4d56; }
  .reason-line_5{         background-color: #ff3a86; }
  .reason-line_6{         background-color: #df1eff; }
  .handset-decor{         background-color: yellow; }
  .reason-wrapper {       outline: 1px dashed wheat; }
  .reason__caption {      background-color: rgba(255, 69, 0, 0.2); outline: 1px dashed orangered; }
}

//#services
._markup{
  .service__features-title{   background-color: orange; }
  .service__price-buttons{    background-color: #b0ff46; }
  .service__buttons-panel{    background-color: #ff95ed; }
  .service_1 .wrapper{        background-color: rgba(255, 165, 0, 0.4); }
  .service_2 .wrapper{        background-color: rgba(255, 98, 29, 0.4); }
  .section-title{             background-color: yellow; }
  .service__chain-list_1{     background-color: #10ff04; }
  .service__chain-list_2{     background-color: #ff8c49; }
  .service__description{      background-color: #ff7459; }
  .small{                     background-color: orangered; }
}

//#stages
._markup{
  .section-title {          background-color: rgba(200, 200, 32, 0.33); }
  .section-title_wrapper {  background-color: rgba(170, 64, 0, 0.33); }
  .step {                   background-color: rgba(255, 0, 0, 0.33); }
  .step__title {            background-color: rgba(32, 200, 32, 0.33); }  // общая подпись нескольких этапов
  .step__number {           outline: 1px solid rgba(255, 69, 0, 0.95); }  // кружочки с номерами этапа
  .stage__caption {         background-color: rgba(255, 69, 0, 0.2);}     // подпись под каждым этапом
  .stage-lines_wrapper {    background-color: rgba(0, 0, 0, 0.33); }
  .stage-line_1 {           background-color: #ffa201; }
  .stage-line_2 {           background-color: #ff6109; }
  .stage-line_3 {           background-color: #ff4712; }
  .stage-line_4 {           background-color: #ff4d56; }
  .stage-line_5 {           background-color: #ff3a86; }
  .stage-line_6 {           background-color: #df1eff; }
  .stage-line_7 {           background-color: #872dff; }
}
@media (max-width: 666px) {
  ._markup-sm{
    .stage {         background-color: rgba(255, 192, 203, 0.36);  outline: 1px dashed orangered; }
    .step_1 {        background-color: rgba(0, 135, 0, 0.5);       outline: 1px solid lime;   }
    .step_2 {        background-color: rgba(80, 135, 22, 0.5);     outline: 1px solid #bfff1f; }
    .step_3 {        background-color: rgba(132, 135, 19, 0.5);    outline: 1px solid yellow; }
    .step_4 {        background-color: rgba(135, 92, 25, 0.5);     outline: 1px solid #ffb339; }
    .step_final {    background-color: rgba(135, 40, 20, 0.5);     outline: 1px solid #ff4c29; }
    .wrapper-666 {   background-color: rgba(255, 140, 0, 0.26); }
  }
}

//#testimonials
._markup{
  .section-title_wrapper {  background-color: rgba(170,64,0,0.33); outline: 1px dashed rgba(170,64,0,1); }
  .section-title {          background-color: rgba(48,170,117,0.33); outline: 1px dashed rgb(48, 170, 117); }
  .cards-slider {           background-color: orange; opacity: 0.33; }
}

//#articles
._markup{
  .tile {                 outline: 2px solid gray; }
  .wrapper{               background-color: #00FF00; outline: 1px solid orangered; }
  .tiles_wrapper{         background-color: orangered; }
  .tile__image{           background-color: #ad8dff;}
  .tile__body{            outline: 1px solid grey; }
  .tile__title{           background-color: #ff5768;}
  .tile__notice{          background-color: #ffbc67;}
  .tile__button-wrapper{  background-color: rgba(30, 205, 106, 0.62); }
}

//#subscribe
._markup{
  .wrapper{                     background-color: rgba(210,108,174,0.42); }
  #subscribe-form{              background-color: grey;    }
  .section-title{               background-color: coral;  }
  .email{                       background-color: #00FF00; }
}

//#contacts
._markup{
  footer .footer__logo{          background-color: rgba(255, 144, 7, 0.33) }
  footer .footer__titles{        background-color: rgba(225, 255, 27, 0.33) }
  footer .footer__titles_small{  background-color: rgba(255, 46, 80, 0.33) }
  .location{                     background-color: rgba(255, 0, 0, 0.09) }
  .phone {                       background-color: rgba(255, 165, 0, 0.16) }
  .wrapper{                      outline: 2px dashed red; }
  .social-links{                 background-color: rgba(255, 244, 0, 0.11);  }
  footer{                        background-color: rgba(255, 69, 0, 0.17); }
  address {                      background-color: rgba(255, 112, 0, 0.33) }
}
