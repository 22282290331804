
/*   -=========== Menus ===========-   */

// Base

.menu {
  ul, ol, li {
    margin: 0;
    list-style: none;
    user-select: none;
  }

  a {
    text-decoration: none;
  }

  .active > a {
    cursor: default;
  }

  li, a {
    user-select: none;
  }

  a:not([href]) {
    cursor: default;
  }
}

.inline-menu {
  li {
    display: inline-block;
  }
}

.horizontal-menu {
  ul {
    clearfix();
  }

  li {
    float: left;
  }
}

.vertical-menu {
  li {
    display: block;
  }
}

.justify-menu {
  > ul, > ol {
    line-height: 0;
    text-align: justify;

    &:after {
      width: 100%;
      height: 0;
      content: "";
      font-size: 0;
      line-height: 0;
      display: inline-block;
      visibility: hidden;
      overflow: hidden;
    }

    > li {
      display: inline-block;
    }
  }
}

.table-menu {
  ul {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  li {
    display: table-cell;
  }
}


// Breadcrumbs

.breadcrumbs {

  li {
    display: inline-block;
    vertical-align: middle;
  }

  a, span {
    color: $gray;
  }

  a {
    text-decoration: none;

    &:hover {
      color: $lt-gray;
    }
  }
}