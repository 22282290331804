
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 1px 72px 0 rgba(0,0,0,.25);
  padding: 64px 64px 48px 64px;
  border-radius: 16px;
  color: $color-gray;
  z-index: 333;

  @media $small-mobile {
    box-sizing: border-box;
    padding: 30px;
  }
}

.popup__close {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    width: 28px; height: 4px;
    background-color: #cfcfcf;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.popup__title{
  font-size: 2.8rem;
  font-weight: 600;
  font-family: 'ProximaNova_Bold', sans-serif;
  text-transform: uppercase;
  color: #5abad2;
  letter-spacing: 0.66px;
  text-align: center;
}

.popup__message{
  font-family: 'ProximaNova_Light', Sans-Serif;
  font-weight: 300;
  font-size: 2.8rem;
  color: #5abad2;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
}
.popup__logo{
  display: block;
  width 110px;
  height 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.popup__note{
  font-family: 'ProximaNova_Light', Sans-Serif;
  font-weight: 300;
  font-size: 1.2rem;
  color: #333333;
  max-width: 380px;
  width 100%;
  text-align: center;
}

.popup__input {
  display block;
  width: 100%
  padding: 6px 0px;
  font-size: 1.8rem;
  background-color: transparent;
  margin-bottom: 20px;
  border-bottom: 2px solid #CFCFCF;
  color: #5abad2;
  font-family: 'ProximaNova_Bold', Sans-Serif;
  font-weight: 600;
  transition: 0.45s;
}
.popup__input::-webkit-input-placeholder {
  font-family: 'ProximaNova_Regular', Sans-Serif;
  font-weight: 400;
  color: #888888;
}
.popup__input:hover::-webkit-input-placeholder {
  color: #5abad2;
}
.popup__input:focus::-webkit-input-placeholder {
  font-family: 'ProximaNova_Regular', Sans-Serif;
  font-weight: 400;
  color: #5abad2;
}
.popup__input:hover,
.popup__input:focus {
  font-family: 'ProximaNova_Bold', Sans-Serif;
  font-weight: 600;
  color: #5abad2;
  border-bottom: 2px solid #5abad2;
}
.popup__button-wrapper{
  padding-bottom: 10px;
  text-align: center;
}
.popup__small-description{
  font-family: 'ProximaNova_Light', Sans-Serif;
  font-weight: 300;
  text-align: center;
  font-size: 12px;
  max-width: 300px;
}
.errorMessage{
  font-size: 1.4rem;
  margin-bottom: 8px;
  color: #eb6060;
}


@media(max-width: 1024px) {

  .popup__title{
    font-size: 2.4rem;
    letter-spacing: 0.55px;
  }
  .popup__input{
    font-size: 1.6rem;
    margin-bottom: 16px;
  }
}

@media(max-width: 568px) {

  .popup__title{
    font-size: 2.0rem;
    letter-spacing: 0.45px;
  }
  .popup__input{
    font-size: 1.4rem;
    margin-bottom: 12px;
  }

}

// fix offset input caret fix on iOS 11
.is-ios.uniloader-overlay-html body{
  position: fixed;
}