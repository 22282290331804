#welcome{
  height: 960px;
  min-height: 960px;
  background-color: #ffffff;
  background-image: url('/i/1_welcome_bg_1.jpg');
  background-position: center center;
  background-size: cover;

  .infographics-bg{
    position: absolute;
    display: block;
    background-image: url('/i/1_welcome_bg_2.jpg');
    background-position-x: center;
    background-size: cover;
    width: 100%;
    height: 960px;
    opacity: 0;
  }
  .infographics{
    position: absolute;
    display: block;
    background-image: url('/i/1_welcome_infographics.png');
    background-size: cover;
    width: 50vw;
    height: 50vw;
    margin-top: 2vh;
    z-index: 1;
    opacity: 0;
    left: -5vw;
  }
  @media(max-width: 800px) {
    .infographics-bg,
    .infographics{
      display: none;
    }
  }

  .wrapper_1{
    position: absolute;
    left: 50%;
    width: 50%;
    height: 980px;
    padding-top: 90px;
    padding-left: 10.9%;
  }
  .wrapper_2{
    line-height: 1;
  }
  .wrapper_3{
    padding-left: 39px;
    padding-top: 42px;
  }
  .logo{
    display: inline-block;
    background-image: url(/i/logo_welcome_blue.png);
    background-size: cover;
    width: 130px;
    height: 130px;
    margin-right: 36px;
    float: left;
  }
  .v-spacer{
    display: block;
    height: 36px;
  }
  .title{
    font-size: 3.0rem;
    font-family: 'ProximaNova_Bold', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #5abad2;
    letter-spacing: 0.5px;
  }
  .slogan{
    font-size: 3.0rem;
    font-family: 'ProximaNova_Regular', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #5abad2;
    letter-spacing: 0.66px;
  }
  .description{
    font-size: 3.8rem;
    font-family: 'ProximaNova_Bold', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #5abad2;
    letter-spacing: 2.15px;
    line-height: 1.25;
    margin-bottom: 36px;
  }
  .chain-list{
    margin-bottom: 50px;
  }
  .btn_trigger{
    color: white;
    background-color: #5abad2;
    border: 2px solid #5abad2;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.13);

    &:hover{
      color: #5abad2;
      background-color: rgba(255,255,255,0);
      border: 2px solid #5abad2;
      box-shadow: 0 0 0 0 rgba(0,0,0,0);
    }
  }


  @media(max-width: 1024px){
    height: 650px;
    min-height: 650px;

    .infographics-bg{
      height: 650px;
    }

    .wrapper_1{
      padding: 20px 30px;
      height: 650px;
    }
    .wrapper_2{}
    .wrapper_3{
      padding-top: 15px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .logo{
      width: 128px;
      height: 128px;
      margin-right: 28px;
    }
    .v-spacer{
      height: 20px;
    }
    .description{
      letter-spacing: 1.1px;
      font-size: 2.6rem;
    }
    .chain-list{
      font-size: 1.6rem;

      li {}
      li:before{
        margin-top: -6px;
      }
      li:after{
        height: 20px;
      }
      li:last-of-type:after{
        height: 0;
      }
    }
  }


  @media(max-width: 800px) {
    height: 962px;
    min-height: 962px;
    background-color: #5abad2;
    background-position-x: left;

    .infographics-bg{
      height: 962px;
    }

    .wrapper_1{
      height: 962px;
      background-color: rgba(74, 164, 185, 0.4);
      width: 100%;
      left: 0;
      padding-top: 90px;
      padding-left: 10%;
      padding-right: 10%;
    }
    .wrapper_2{ }
    .wrapper_3{
      padding-top: 18px;
      padding-left: 32px;
      padding-right: 32px;
      color: #fff;
    }

    .logo {
      display: inline-block;
      width: 108px;
      height: 108px;
      margin-right: 24px;
      background-image: url(/i/logo_welcome_white.png);
    }
    .v-spacer{
      height: 18px;
    }
    .slogan{
      font-family: 'ProximaNova_Light', sans-serif;
      font-weight: 300;
    }
    .title,
    .slogan,
    .description{
      color: #fff;
    }
    .chain-list{
      font-size: 1.8rem;
      color: #fff;

      li {}
      li:before{
        border-color: #ffffff;
        margin-top: -6px;
        background-image: url(/i/icon-success_white.svg);
      }
      li:after{
        border-color: #ffffff;
        margin-top: -8px;
        height: 20px;
      }
      li:last-of-type:after{
        height: 0;
      }
    }
    .btn_trigger{
      color: #5abad2;
      background-color: white;
      border: 2px solid white;
      box-shadow: 0 0 6px 0 rgba(0,0,0,.13);

      &:hover{
        color: white;
        background-color: transparent;
        border: 2px solid white;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }
    }
  }


  @media(max-width: 568px) {
    height: 420px;
    min-height: 420px;

    .infographics-bg{
      height: 420px;
    }

    .wrapper_1{
      background-color: rgba(90, 186, 210, 0.66);
      height: 420px;
      left: 0;
      width: 100%;
      right: 0;
      padding-top: 24px;
    }
    .wrapper_2{}
    .wrapper_3{
      padding-top: 0px;
      text-align: center;
    }
    h1{
      line-height: 0.75;
    }
    .logo{
      width: 72px;
      height: 72px;
    }
    .v-spacer{
      height: 9px;
    }
    .title{
      font-size: 2.2rem;
    }
    .slogan{
      font-size: 2.2rem;
    }
    .description {
      font-size: 2.0rem;
      letter-spacing: 0.4px;
      padding-bottom: 0;
      margin-bottom: 24px;
      text-align: left;
    }
    .chain-list{
      font-size: 1.4rem;
      margin-bottom: 32px;
      text-align: left;

      li{
        padding-left: 20px;
        margin-bottom: 15px;
      }
      li:before{
        width: 24px;
        height: 24px;
        background-size: 12px;
        margin-top: -6px;
        margin-left: -36px;
        background-image: url(/i/icon-success_white.svg);
      }
      li:after{
        margin-left: -25px;
        margin-top: -8px;
        height: 18px;
      }
      li:last-of-type:after{
        height: 0;
      }

    }
  }

  @media(max-width: 414px) { //xs iPhone6+
    height: 500px;
    min-height: 500px;

    .infographics-bg{
      height: 500px;
    }

    .wrapper_1{ height: 500px; }
    .wrapper_2{}
    .wrapper_3{}
  }

  @media(max-width: 375px) { //xs iPhone6
    height: calc(100vh - 64px);
    min-height: calc(100vh - 64px);

    .wrapper_1{
      height: calc(100vh - 64px);
    }
    .wrapper_2{}
    .wrapper_3{
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .chain-list{
      margin-bottom: 64px;
    }
  }

  @media(max-width: 320px) {  //xs iPhone5
    height: 506px;
    min-height: 506px;

    .infographics-bg{
      height: 506px;
    }

    .wrapper_1{
      background-color: rgba(90, 186, 210, 0.4);
      height: 506px;
      left: 0%;
      width: 100%;
      padding-top: 24px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .wrapper_2{
      padding-top: 0;
      margin-top: 0;
      line-height: 0.75;
    }
    .wrapper_3{
      padding: 0;
      margin: 0;
      text-align: center;
    }
    .logo{
      width: 64px;
      height: 64px;
      margin-bottom: 20px;
      float: none;
      display: block;
    }
    .v-spacer{
    }
    .title{
      font-size: 2.2rem;
    }
    .slogan{
      font-size: 2.2rem;
    }
    .description {
      margin-bottom: 20px;
    }
    .chain-list{
      margin-bottom: 40px;
    }
  }

}
