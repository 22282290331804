main.book{

  .introduction {
    background-image: url('/i/book_02_introduction_bg.jpg');
    background-repeat: no-repeat;
    background-position-x: -64px;
    background-position-y: 100px;
    background-size: contain;
    display: block;
    height: auto;

    .section-title{
      text-align: center;
      padding-top: 74px;
      padding-bottom: 60px;
    }
    .wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .btn_wrapper{
      text-align: center;
      padding-top: 40px;
      padding-bottom: 50px;
    }
    .btn_trigger{
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: initial;
      max-width: 320px;
      width: 320px;
    }
    .cell {
      width: 33.3333%;
      max-width: 33.3333%;
      flex-basis: 33.3333%;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 32px;
    }
    .title{
      color: #59bad1;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: 'ProximaNova_Bold', Sans-serif;
      margin-bottom: 32px;
    }
    .descr{
      color: #1a1a1a;
      font-size: 15px;
    }
    .btn_trigger{
      box-shadow: 0 0 24px rgba(0,0,0, .12);
    }
    .btn_trigger:hover{
      box-shadow: none;
    }
    @media only screen and (max-width: 1024px){
      background: none;

      .section-title{
        padding-top: 64px;
        padding-bottom: 48px;
      }
      .first_col{
        display: none;
      }
      .cell{
        padding-bottom: 28px;
      }
      .title{
        margin-bottom: 16px;
      }
    }
    @media only screen and (max-width: 768px) {

      .cell {
        width: 50%;
        max-width: 50%;
        flex-basis: 50%;
      }
    }
    @media only screen and (max-width: 480px){

      .cell {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
      .btn_wrapper{
        padding-top: 10px;
        padding-bottom: 40px;
      }
      .btn_trigger{
        width: 240px;
      }
    }
  }

}
