#animation{
  display: block;
  background-color: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;

  .section-title{
    color: #5abad2;

    &_wrapper{
      position: absolute;
      z-index: 10;
      text-align: center;
      width: 100%;
      display: block;
    }
  }
  .wrapper{
    margin-left: auto;
    margin-right: auto;
  }
  .slick-slide:focus{
    outline: none;
  }

  @media(min-width: 320px) {
    display: none;
    padding-bottom: 10px;
    .wrapper{ width: 300px; }
  }
  @media(min-width: 568px) {
    display: none; //       <-- на мобильных убираем баннер
    padding-bottom: 20px;
    .wrapper{ width: 500px; }
  }
  @media(min-width: 768px) {
    display: block;
    padding-bottom: 30px;
    .wrapper{ width: 720px; }

    .hype-anim_wrapper{
      margin-top: 20px;
      height: 320px;
    }
  }
  @media(min-width: 1024px) {
    padding-bottom: 40px;
    .wrapper{ width: 1000px; }

    .hype-anim_wrapper{
      margin-top: 30px;
      height: 400px;
    }
  }

  @media(min-width: 1200px) {
    .hype-anim_wrapper{
      margin-top: 35px;
      height: 430px;
    }
  }

  @media(min-width: 1280px) {
    padding-bottom: 50px;
    .wrapper{ width: 1250px; }

    .hype-anim_wrapper{
      margin-top: 40px;
      height: 480px;
    }
  }

  @media(min-width: 1920px) {
    .hype-anim_wrapper{
      margin-top: 35px;
      height: 695px;
    }
  }

}