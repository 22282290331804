
/* -=====  CSS Animation Effects =====- */


// -----  эффект пульсации кнопки -----
.pulse-button {
  box-shadow: 0 0 0 0 rgba(171, 203, 233, 0.7);
  animation: pulse 1.75s infinite cubic-bezier(0.6, 0, 0, 1);
}
.pulse-button:hover {
  animation: none;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
  }
}

// -----  мягкий сдвиг вверх/вниз -----
.sliding-invitation {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.75s;
  animation-name: updown;
}
.sliding-invitation:hover {
  animation: none;
}
@keyframes updown {
  from {    margin-top: 0; }
  66%  {    margin-top: 8px; }
  to   {    margin-top: 0; }
}


// -----  приветственная анимация в хедере -----
.greetings {
  display: block;
  width: 300px;
  height: 21px;
  margin-top: 29px;
  position: absolute;
  z-index: 1000;
  left: calc(5% + 242px + 32px);

  .dot {
    display: block;
    width: 10px;
    height: 10px;
    margin-top: 6px;
    border-radius: 50%;
    position: absolute;
  }
  .dot_1 {
    background-color: #d6f2fa;
    margin-left: 2px;
    opacity: 0;
  }
  .dot_2 {
    background-color: #abe1f4;
    margin-left: 22px;
    opacity: 0;
  }
  .dot_3 {
    background-color: #8bbdcf;
    margin-left: 42px;
    opacity: 0;
  }
  .label {
    white-space: nowrap;
    color: #333;
    opacity: 0;
    font-size: 17px;
  }

  .dot_1,
  .dot_2,
  .dot_3,
  .label {
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-duration: 5.00s;
  }
  .dot_1 { animation-name: greeting_dot1; }
  .dot_2 { animation-name: greeting_dot2; }
  .dot_3 { animation-name: greeting_dot3; }
  .label { animation-name: greeting_label; }
}

@keyframes greeting_dot1 {
  from  { opacity: 0; }
  12%   { opacity: 1; }
  24%   { opacity: 0; }
  36%   { opacity: 1; }
  48%   { opacity: 0; }
  to    { opacity: 0; }
}
@keyframes greeting_dot2 {
  from  { opacity: 0; }
  15%   { opacity: 1; }
  27%   { opacity: 0; }
  39%   { opacity: 1; }
  51%   { opacity: 0; }
  to    { opacity: 0; }
}
@keyframes greeting_dot3 {
  from  { opacity: 0; }
  18%   { opacity: 1; }
  30%   { opacity: 0; }
  42%   { opacity: 1; }
  54%   { opacity: 0; }
  to    { opacity: 0; }
}
@keyframes greeting_label {
  from  { opacity: 0; }
  57%   { opacity: 0; }
  67%   { opacity: 1; }
  93%   { opacity: 1; }
  to    { opacity: 0; }
}

@media only screen and (max-width: 1280px) {
  .greetings {
    margin-top: 60px;
    left: auto;
    right: calc(285px + 5%);

    .label {
      font-size: 17px;
      text-align: right;
      color: #666;
    }
    .dot_1 { right: 42px; }
    .dot_2 { right: 22px; }
    .dot_3 { right: 2px; }
  }
}
@media only screen and (max-width: 1024px) {
  .greetings {
    margin-top: 27px;
    left: calc(10% + 140px);

    .label {
      white-space: nowrap;
      color: #333;
      opacity: 0;
      font-size: 14px;
      text-align: left;
    }
    .dot {
      margin-top: 2px;
      width: 8px;
      height: 8px;
    }
    .dot_1,
    .dot_2,
    .dot_3 {
      right: auto;
    }
  }
}
@media only screen and (max-width: 800px) {
  .greetings {
    display: none;
  }
}


