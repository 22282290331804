
/* -===== Startup styles in-view appearance =====- */


// #CASES startup accearance
.in-view .case-item{
  opacity: 1 !important;
}
.case-item{
  opacity: 0;
  transition: 0.66s;
}
.case-item_1{ transition-delay: 0.16s; }
.case-item_2{ transition-delay: 0.33s; }
.case-item_3{ transition-delay: 0.49s; }
.case-item_4{ transition-delay: 0.66s; }
.case-item_5{ transition-delay: 0.82s; }
.case-item_6{ transition-delay: 0.99s; }

@media (max-width: 720px){
  .case-item{
    opacity: 0;
    transition: 0.66s;
  }
  .case-item_1{ transition-delay: 0.16s; }
  .case-item_2{ transition-delay: 0.33s; }
  .case-item_3{ transition-delay: 0.49s; }
  .case-item_4{ transition-delay: 0.66s; }
  .case-item_5{ transition-delay: 0.82s; }
  .case-item_6{ transition-delay: 0.99s; }
}

// #REASONS startup appearance (will be animated by JS)
//
#reasons{
  .reason-line_1,
  .reason-line_2,
  .reason-line_3,
  .reason-line_4,
  .reason-line_5,
  .reason-line_6,
  .reason__number,
  .handset-decor{
    display: none;
  }
}


// #SERVICES lists (will be animated by JS)
//


// #STAGES (will be animated later)
//


// #TESTIMONIALS startup appearance
//
#testimonials{
  .card{
    opacity: 0;
    margin-top: 20px;
    transition: 0.66s;
  }
  .in-view .card{
    opacity: 1;
    margin-top: 0;
  }
}


// #ARTICLES startup appearance
//
#articles{
  .tile{
    opacity: 0;
    margin-top: 20px;
    transition: 0.66s;
  }
  .in-view .tile{
    opacity: 1;
    margin-top: 0;
  }
}
