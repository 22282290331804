main.book {

  .profit {
    display: block;
    background-image: url('/i/book_03_profit_bg.jpg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    height: 430px;

    .tint {
      background-color: rgba(83, 146, 190, 0);
      padding-top: 105px;
      height: 100%;
    }
    .section-title {
      color: #fff;
      margin-bottom: 70px;
    }
    .chain-list {
      color: #ffffff;
      font-weight: 500;
      font-family: 'ProximaNova_Regular', Sans-Serif;

      li {
      }
      li:before {
        border-color: #ffffff;
        margin-top: -6px;
        background-image: url(/i/icon-success_white.svg);
      }
      li:after {
        border-color: #ffffff;
        margin-top: -8px;
        height: 20px;
      }
      li:last-of-type:after {
        height: 0;
      }
    }
    @media only screen and (max-width: 767px) {
      background-size: cover;
      height: auto;

      .tint {
        background-color: rgba(83, 146, 190, 0.75);
        padding-top: 64px;
        padding-bottom: 64px;
      }

      .section-title {
        color: #fff;
        margin-bottom: 32px;
      }
    }
    @media only screen and (max-width: 480px) {
      .tint {
        background-color: rgba(83, 146, 190, 0.85);
      }
    }
  }

}