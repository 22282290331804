
/*   -=========== Contact ===========-   */

.contacts {}

.contacts--map {
  position: relative;

  > .map {
    width: 100%;
    height: 515px;
  }
}

.contacts--panel {
  position: absolute;
  top: 50%; left: 50%;
  transform: translateY(-50%);
  width: 345px;
  padding: 15px 0;
  margin-left: -625px;
  background: $white;
  z-index: 15;

  @media only screen and (max-width: $wrapper-width) {
    left: 55%;
  }

  > .group {
    font-size: 14px;
    margin: 0 15px;
    line-height: 1.3em;

    > p {
      padding-left: 34px;
    }
  }
}