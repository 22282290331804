main.book{

  .publisher{
    display: block;
    background-image: url('/i/book_10_publisher_bg.jpg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    height: 560px;
    color: #fff;
    font-weight: 500;
    font-family: 'ProximaNova_Regular', Sans-Serif;

    .tint{
      background-color: rgba(83, 146, 190, 0);
      padding-top: 116px;
      height: 100%;
    }
    .section-title{
      margin-bottom: 74px;
      color: #fff;
    }
    p{
      margin-bottom: 20px;

      a{
        color: #fff;
        transition: 0.33s;
      }
      a:hover,
      a:focus{
        color: #65e0fa;
      }
    }

    @media only screen and (max-width: 1024px) {
      background-position-x: 66%;
    }

    @media only screen and (max-width: 768px) {
      background-position-x: 75%;
      .tint{
        background-color: rgba(83, 146, 190, 0.33);
      }
    }

    @media only screen and (max-width: 480px){
      .tint{
        padding-top: 64px;
        background-color: rgba(83, 146, 190, 0.5);
      }
      .section-title{
        margin-bottom: 48px;
      }
    }
  }

}