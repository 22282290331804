main.book{

  .preorder{
    display: block;
    height: auto;
    background-color: #D4F1F8;
    background-image: url('/i/book_01_preorder_bg.jpg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;

    .title {
      padding-top: 176px;
      padding-bottom: 42px;
      color: #5abad2;
      font-weight: 700;
      font-family: 'ProximaNova_Bold', Sans-serif;
      font-size: 42px;
      text-transform: uppercase;
    }
    .divider{
      display: block;
      background-color: #5abad2;
      width: 44px;
      height: 3px;
      margin-bottom: 42px;
    }
    .subtitle{
      color: #5abad2;
      font-weight: 300;
      font-family: 'ProximaNova_Light', Sans-serif;
      font-size: 32px;
      text-transform: uppercase;
    }
    .description{
      display: block;
      color: #444444;
      padding-top: 24px;
      padding-bottom: 80px;
    }
    .btn_wrapper{
      display: block;
      width: 100%;
      padding-bottom: 250px;
      border-radius: 0;
    }
    .btn_trigger{
      color: white;
      background-color: #5abad2;
      border: 2px solid #5abad2;
      box-shadow: 0 0 6px 0 rgba(0,0,0,.13);

      &:hover{
        color: #5abad2;
        background-color: rgba(255,255,255,0.42);
        border: 2px solid #5abad2;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }
    }
    @media only screen and (max-width: 1440px){
      background-position-x: -10vw;
      height: auto;

      .title{
        padding-top: 128px;
      }
      .btn_wrapper{
        padding-bottom: 150px;
      }
    }
    @media only screen and (max-width: 1280px){
      background-position-x: -10vw;

      .wrapper{
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 0;
        background-color: rgba(212, 241, 248, 0.4);
        background: -moz-linear-gradient(-45deg,  rgba(212,241,248,1) 0%, rgba(190,232,251,0.85) 43%, rgba(178,227,253,0.33) 66%, rgba(166,221,254,0.1) 91%, rgba(161,219,255,0) 100%);
        background: -webkit-linear-gradient(-45deg,  rgba(212,241,248,1) 0%,rgba(190,232,251,0.85) 43%,rgba(178,227,253,0.33) 66%,rgba(166,221,254,0.1) 91%,rgba(161,219,255,0) 100%);
        background: linear-gradient(135deg,  rgba(212,241,248,1) 0%,rgba(190,232,251,0.85) 43%,rgba(178,227,253,0.33) 66%,rgba(166,221,254,0.1) 91%,rgba(161,219,255,0) 100%);
      }
      .title{
        padding-top: 80px;
      }
      .btn_wrapper{
        padding-bottom: 120px;
      }
    }
    @media only screen and (max-width: 1024px){
      background-position-x: 66%;
    }
    @media only screen and (max-width: 768px){
      background-size: cover;
      background-position-x: calc(45% - 100px);

      .title{
        font-size: 32px;
      }
      .subtitle{
        font-size: 24px;
      }
    }
    @media only screen and (max-width: 568px){
      background-position-x: calc(100% + 180px);

      .title{
        font-size: 28px;
        padding-top: 60px;
      }
      .subtitle{
        font-size: 20px;
        font-weight: bold;
      }
      .description{
        padding-bottom: 50px;
      }
      .wrapper{
        background: rgba(212, 241, 248, 0.85);
      }
      .btn_wrapper{
        padding-bottom: 90px;
        text-align: center;
      }
    }
  }

}


