.tos-slide,.tos-slider,.tos-wrapper{width:100%;height:100%}
.tos-noanimation{-webkit-transition-property:none!important;transition-property:none!important}
.tos-fastanimation{-webkit-transition-duration:.2s!important;transition-duration:.2s!important;-webkit-transition-timing-function:ease-out!important;transition-timing-function:ease-out!important}
.tos-wrapper{opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;display:none;padding:0;margin:0;overflow:hidden}
.tos-wrapper.tos-opened{display:block}
.tos-slide,.tos-slide:before,.tos-slide>*{display:inline-block}
.tos-wrapper.tos-opening{opacity:1}
.tos-wrapper.tos-fixed{background-color:#000;position:fixed;top:0;left:0;z-index:9000}
.tos-slider,.tos-wrapper.tos-inline{position:relative}
.tos-slider{white-space:nowrap;padding:0;margin:0}
.tos-uibg,.tos-wrapper.tos-fx-slide.tos-fixed .tos-slider{position:absolute}
.tos-wrapper.tos-fx-slide .tos-slider{left:0;-webkit-transition:left .4s ease;transition:left .4s ease}
.tos-wrapper.tos-fx-fade .tos-slider{opacity:1;-webkit-transition:opacity .4s ease;transition:opacity .4s ease}
.tos-uibg{opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;width:100%;left:0;bottom:0;z-index:1}
.tos-fill .tos-uibg{background:-webkit-linear-gradient(bottom,rgba(0,0,0,.5),transparent);background:linear-gradient(to top,rgba(0,0,0,.5),transparent)}
.tos-desktop .tos-wrapper:hover .tos-uibg,.tos-touch .tos-wrapper.tos-hover .tos-uibg{opacity:1}
.tos-slide{-webkit-overflow-scrolling:touch;line-height:1px;text-align:center;box-sizing:border-box;overflow:hidden;position:relative}
.tos-slide:before{content:"";height:50%;width:1px;margin-right:-1px}
.tos-slide.tos-loading>*{opacity:0}
.tos-slide>*{opacity:1;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;vertical-align:middle;max-height:100%;max-width:100%;box-sizing:border-box}
.tos-slide.tos-html>div{-webkit-overflow-scrolling:touch;white-space:normal;text-align:left;line-height:1.5}
.tos-slide.tos-html>div *{-webkit-text-size-adjust:none;-ms-text-size-adjust:none;text-size-adjust:none}
.tos-wrapper.tos-fill .tos-slide.tos-image>img{max-height:none;max-width:none;min-height:100%;min-width:100%}
.tos-wrapper.tos-fixed .tos-slide.tos-html>div{background-color:#fff;color:#333;box-sizing:border-box;display:inline-block;padding:40px;overflow:auto}
.tos-desktop .tos-wrapper.tos-fixed{background-color:rgba(0,0,0,.85)}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit .tos-slide{padding:20px}
.tos-close,.tos-next{right:20px}
.tos-close,.tos-next,.tos-prev{background:#000;border-radius:3px;opacity:0;display:block;width:40px;position:absolute;z-index:1;-webkit-transition:opacity .4s ease;transition:opacity .4s ease}
.tos-next,.tos-prev{height:80px;margin-top:-40px;top:50%}
.tos-next.tos-disabled,.tos-prev.tos-disabled{cursor:default}
.tos-prev{left:20px}
.tos-close{height:40px;top:20px}
.tos-desktop .tos-wrapper:hover .tos-close,.tos-desktop .tos-wrapper:hover .tos-next,.tos-desktop .tos-wrapper:hover .tos-prev,.tos-touch .tos-wrapper.tos-hover .tos-close,.tos-touch .tos-wrapper.tos-hover .tos-next,.tos-touch .tos-wrapper.tos-hover .tos-prev{opacity:.5}
.tos-desktop .tos-wrapper:hover .tos-close:hover,.tos-desktop .tos-wrapper:hover .tos-next:hover,.tos-desktop .tos-wrapper:hover .tos-prev:hover,.tos-touch .tos-wrapper.tos-hover .tos-close:hover,.tos-touch .tos-wrapper.tos-hover .tos-next:hover,.tos-touch .tos-wrapper.tos-hover .tos-prev:hover{opacity:.9}
.tos-desktop .tos-wrapper:hover .tos-close.tos-disabled,.tos-desktop .tos-wrapper:hover .tos-next.tos-disabled,.tos-desktop .tos-wrapper:hover .tos-prev.tos-disabled,.tos-touch .tos-wrapper.tos-hover .tos-close.tos-disabled,.tos-touch .tos-wrapper.tos-hover .tos-next.tos-disabled,.tos-touch .tos-wrapper.tos-hover .tos-prev.tos-disabled{opacity:.2}
.tos-close span:after,.tos-close span:before,.tos-next span,.tos-prev span{content:'';display:block;width:12px;height:12px;margin:-5px;position:absolute;top:50%;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.tos-close span:after,.tos-close span:before{width:6px;height:6px;margin-top:-4px;margin-left:0;margin-right:0}
.tos-close span:before,.tos-prev span{border-bottom:3px solid #fff;border-left:3px solid #fff;left:50%}
.tos-close span:after,.tos-next span{border-top:3px solid #fff;border-right:3px solid #fff;right:50%}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-close .tos-slide,.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-next .tos-slide,.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-prev .tos-slide{padding-left:80px;padding-right:80px}
.tos-inline{opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;display:inline-block;margin:0;position:relative;top:auto;left:auto;right:auto}
.tos-inline.tos-prev{margin-right:-60px}
.tos-inline.tos-next{margin-left:-60px}
.tos-loading .tos-inline{opacity:0!important}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-close,.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-next,.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-prev{-webkit-transform:scale(2);-ms-transform:scale(2);transform:scale(2)}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-close,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-next,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-prev{-webkit-transform:scale(3);-ms-transform:scale(3);transform:scale(3)}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-prev,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-prev{-webkit-transform-origin:left center;-ms-transform-origin:left center;transform-origin:left center}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-next,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-next{-webkit-transform-origin:right center;-ms-transform-origin:right center;transform-origin:right center}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-close,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-close{-webkit-transform-origin:right top;-ms-transform-origin:right top;transform-origin:right top}
.tos-caption{color:#fff;text-align:center;opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;width:100%;position:absolute;left:0;bottom:0;z-index:1}
.tos-caption:after{content:'';display:block;clear:both}
.tos-caption>div{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;width:100%;box-sizing:border-box;padding:0 20px;float:left}
.tos-caption>div:last-child{float:right;margin-right:-2px}
.tos-desktop .tos-wrapper:hover .tos-caption,.tos-touch .tos-wrapper.tos-hover .tos-caption{opacity:1}
.tos-wrapper .tos-caption{line-height:20px;font-size:15px;text-shadow:0 1px 2px rgba(0,0,0,.8);height:40px}
.tos-wrapper.tos-has-caption .tos-uibg{height:100px}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-caption .tos-slide{padding-bottom:60px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-caption{line-height:40px;font-size:30px;text-shadow:0 2px 4px rgba(0,0,0,.8);height:80px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-caption .tos-uibg{height:200px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-caption{line-height:60px;font-size:45px;text-shadow:0 3px 6px rgba(0,0,0,.8);height:120px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-caption .tos-uibg{height:300px}
.tos-wrapper.tos-fx-slide .tos-slider{-webkit-transition-property:left,margin;transition-property:left,margin}
.tos-pagination{text-align:center;white-space:nowrap;overflow-x:auto;-webkit-overflow-scrolling:touch;overflow-scrolling:touch;opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease;width:100%;position:absolute;bottom:0;left:0;z-index:1}
.tos-pagination a{display:inline-block}
.tos-pagination.tos-bullets a{background-color:rgba(255,255,255,.2)}
.tos-pagination.tos-bullets a:hover{background-color:rgba(255,255,255,.5)}
.tos-pagination.tos-bullets a.tos-selected{background-color:rgba(255,255,255,.9)}
.tos-desktop .tos-wrapper:hover .tos-pagination,.tos-touch .tos-wrapper.tos-hover .tos-pagination{opacity:1}
.tos-wrapper .tos-pagination:after,.tos-wrapper .tos-pagination:before{content:'';display:inline-block;width:20px;height:10px}
.tos-wrapper.tos-has-bullets .tos-pagination{height:30px}
.tos-wrapper.tos-has-bullets .tos-pagination a{border-radius:10px;width:10px;height:10px;margin:0 5px}
.tos-wrapper.tos-has-bullets .tos-caption{bottom:30px}
.tos-wrapper.tos-has-bullets .tos-uibg{height:90px}
.tos-wrapper.tos-has-bullets.tos-has-caption .tos-uibg{height:130px}
.tos-wrapper.tos-has-thumbnails .tos-pagination{height:70px}
.tos-wrapper.tos-has-thumbnails .tos-pagination a{background-position:center center;background-size:cover;width:50px;height:50px;margin:0 5px}
.tos-wrapper.tos-has-thumbnails .tos-caption{bottom:70px}
.tos-wrapper.tos-has-thumbnails .tos-uibg{height:130px}
.tos-wrapper.tos-has-thumbnails.tos-has-caption .tos-uibg{height:170px}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-bullets .tos-slide{padding-bottom:50px}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-bullets.tos-has-caption .tos-slide{padding-bottom:80px}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-thumbnails .tos-slide{padding-bottom:90px}
.tos-desktop .tos-wrapper.tos-fixed.tos-fit.tos-has-thumbnails.tos-has-caption .tos-slide{padding-bottom:120px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-pagination:after,.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-pagination:before{content:'';display:inline-block;width:40px;height:20px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-bullets .tos-pagination{height:60px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-bullets .tos-pagination a{border-radius:20px;width:20px;height:20px;margin:0 10px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-bullets .tos-caption{bottom:60px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-bullets .tos-uibg{height:180px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-bullets.tos-has-caption .tos-uibg{height:260px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-pagination{height:140px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-pagination a{background-position:center center;background-size:cover;width:100px;height:100px;margin:0 10px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-caption{bottom:140px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-uibg{height:260px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed.tos-has-thumbnails.tos-has-caption .tos-uibg{height:340px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-pagination:after,.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-pagination:before{content:'';display:inline-block;width:60px;height:30px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-bullets .tos-pagination{height:90px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-bullets .tos-pagination a{border-radius:30px;width:30px;height:30px;margin:0 15px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-bullets .tos-caption{bottom:90px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-bullets .tos-uibg{height:270px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-bullets.tos-has-caption .tos-uibg{height:390px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-pagination{height:210px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-pagination a{background-position:center center;background-size:cover;width:150px;height:150px;margin:0 15px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-caption{bottom:210px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-thumbnails .tos-uibg{height:390px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed.tos-has-thumbnails.tos-has-caption .tos-uibg{height:510px}
.tos-slide .tos-html{-webkit-overflow-scrolling:touch}
.tos-play,.tos-play:after{display:block;position:absolute;top:50%;left:50%}
.tos-play{background:#000;opacity:0;-webkit-transition:opacity .4s ease;transition:opacity .4s ease}
.tos-play:after{content:''}
.tos-desktop .tos-wrapper:hover .tos-play,.tos-touch .tos-wrapper.tos-hover .tos-play{opacity:.5}
.tos-desktop .tos-wrapper:hover .tos-play:hover,.tos-touch .tos-wrapper.tos-hover .tos-play:hover{opacity:.9}
.tos-wrapper .tos-play{border-radius:80px;width:80px;height:80px;margin:-40px}
.tos-wrapper .tos-play:after{border:20px solid transparent;border-left-color:#fff;border-left-width:30px;margin-top:-20px;margin-left:-10.5px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-play{border-radius:160px;width:160px;height:160px;margin:-80px}
.tos-touch.tos-scale-2 .tos-wrapper.tos-fixed .tos-play:after{border:40px solid transparent;border-left-color:#fff;border-left-width:60px;margin-top:-40px;margin-left:-21px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-play{border-radius:240px;width:240px;height:240px;margin:-120px}
.tos-touch.tos-scale-3 .tos-wrapper.tos-fixed .tos-play:after{border:60px solid transparent;border-left-color:#fff;border-left-width:90px;margin-top:-60px;margin-left:-31.5px}