
/*   -=========== Typography ===========-   */

html {
  font: $root-font-size / $line-height $font-family;
  font-weight: 400;

  @media $mobile {
    font-size: $root-mobile-font-size;
  }
}

body {
  background-color: $color-white;
  color: $color-gray;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection,
::-moz-selection{
  background: $color-blue;
  color: $color-white;
}



// Headings

.h1, .h2, .h3, .h4 {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}

.h1 {
  font-size: 3.6rem;
}

.h2 {
  font-size: 2.2rem;
}

.h3 {
  font-size: 1.8rem;
}

.h4 {
  font-size: 1rem;
}


// Sub, sup

sub, sup {
  position: relative;
  font-size: .6em;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


// Links

a {
  color: $color-black;

  &:hover {
    color: $color-black;
  }
}

a.hova, .hova a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.unhova, .unhova a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

a.nova, .nova a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

a[href^="tel:"] {
  text-decoration: none;

  .is-desktop & {
    cursor: text;
    pointer-events: none;
  }
}


// Sizes

for $i in (10..40) {
  .s{$i} {
    font-size: remove-unit(1 / $root-font-size) * $i * 1rem;
    line-height: $line-height;
  }
}


// font weight
.thin, .tt   { font-weight: $thin;    font-family: 'ProximaNova_Light', sans-serif;}
.light, .ll  { font-weight: $light;   font-family: 'ProximaNova_Light', sans-serif; }
.normal, .nn { font-weight: normal;   font-family: 'ProximaNova_Regular', sans-serif; }
.bold, .bb   { font-weight: bold;     font-family: 'ProximaNova_Bold', sans-serif; }
.medium, .mm { font-weight: $medium;  font-family: 'ProximaNova_Bold', sans-serif; }

// font style
.style-normal { font-style: normal; }
.italic, .ii  { font-style: italic; }

// text case
.uppercase,
.upcase { text-transform: uppercase; }
.nocase { text-transform: none !important; }

// text decoration
.underline { text-decoration: underline; }

// colors
.black, a.black { color: $color-black; }
.white, a.white { color: $color-white; }

// text align
.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }

// word wrap
.nowrap { white-space: nowrap; }


// text container
.text-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  clearfix();

  * + *:not(.no-margin) {
    margin-top: 1em;
  }

  ol {
    margin-left: 1em;
    list-style-position: outside;
  }

  ul > li {
    list-style: none;

    &:before {
      position: absolute;
      display: block;
      text-align: top;
      content: '●';
      font-size: .45em;
      margin-right: .5em;
      margin-left: -2em;
      margin-top: 0.85em;
    }
  }

  li {
    margin: .25em 0;
    margin-left: 1.5em;

    > ul, ol {
      margin-left: 1em;
    }
  }

  a{
    color: #5abad2;
    font-weight: 400;
    font-family: 'ProximaNova_Regular', sans-serif;
  }
  .description{
    font-family: 'ProximaNova_Regular', sans-serif;
    font-weight: 400;
    font-size: smaller;
    color: #000;
  }
}

// HR

hr {
  height: 1px;
  margin: .5em 0;
  background: $color-black;
}

// Icons

.icon {
  display: inline-block;
}

// Video

.video {
  position: relative;
  padding-bottom: (9 / 21) * 100%; // 21:9 aspect ratio

  > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  &.ar-16by9 {
    padding-bottom: (9 / 16) * 100%; // 16:9 aspect ratio
  }

  &.ar-4by3 {
    padding-bottom: (3 / 4) * 100%; // 4:3 aspect ratio
  }
}

.gray-selection{
  background: #ededed;
  padding-left: 5px;
  padding-right: 5px;
}
.blue-selection{
  background-color: #cff7ff;
  padding-left: 5px;
  padding-right: 5px;
}
.quote{
  background: #ededed;
  padding: 20px 35px;
}
.text-incut{
  background: #ededed;
  padding: 25px 20px;
  font-size: 1.5rem;
  line-height: 1.2;
}
.left-indent{
  margin-left: 40px;
}
.article-summary{
  display: block;
  background-image: url('/i/article-summary.jpg');
  background-size: cover; color: #222;
  border-radius: 3px;
  padding: 2em 3em 2em 3em;

  @media only screen and (max-width: 480px){
    padding: .5em;
  }

  table tr td {
    padding: 2px;
  }
}
.author{
  text-align: right;
  font-style: italic;
}
