#cases{
  font-family: 'ProximaNova_Regular', sans-serif;
  font-weight: 400;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 40px;
  //height: 1010px;

  .v-spacer{
    display: block;
    height: 86px;
  }
  .wrapper{
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .wrapper_1{
    width: 63%;
    padding-left: 15px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
  }
  .wrapper_2{
    width: 35.5%;
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-right: 15px;
  }
  .section-title_wrapper{
    display: block;
    width: 100%;
    padding: 40px 0 30px 0;
  }
  .section-title{
    font-family: 'ProximaNova_Bold', sans-serif;
    font-weight: 600;
  }
  .case-items_wrapper{
    height: 600px;
  }
  .case-item{
    display: inline-block;
    width: 49.5%;
    height: 30%;

    &__icon{
      display: block;
      margin-right: 10px;
      margin-bottom: 10px;
      max-width: 64px;
      background-repeat: no-repeat;
    }
  }
  .btn_trigger{
    display: none;
  }

  @media(max-width: 1024px) { //lg
    //height: 868px;

    .form {
      display: none;
    }
    .section-title_wrapper{
      padding-left: 5%;
    }
    .v-spacer{
      height: 0;
    }
    .wrapper{
      max-width: 980px;
    }
    .wrapper_1{
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 640px;
    }
    .wrapper_2{
      width: 100%;
      text-align: center;
    }
    .case-items_wrapper{
      padding-left: 15%;
      padding-right: 15%;
    }
    .case-item{
      height: 150px;
      padding-left: 10px;
      padding-right: 10px;

      &__caption{
        font-size: 1.6rem;
      }
    }
    .btn_trigger{
      display: inline-block;
      color: white;
      background-color: #5abad2;
      border: 2px solid #5abad2;
      box-shadow: 0 0 6px 0 rgba(0,0,0,.13);

      &:hover{
        color: #5abad2;
        background-color: transparent;
        border: 2px solid #5abad2;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
      }
    }
  }
  @media(max-width: 768px) { //md
    //height: 860px;

    .case-items_wrapper{
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  @media(max-width: 720px) { //<md
    //height: 680px;

    .section-title_wrapper{
      padding: 40px 0 35px 0;
    }
    .section-title{
      font-size: 2.4rem;
      letter-spacing: 0.66px;
      text-align: center;
      color: #5abad2;
    }
    .wrapper_1{
      height 560px;
    }
    .case-items_wrapper{
      padding-left: 0%;
      padding-right: 0%;
    }
    .case-item{
      width: calc(100% - 120px);
      display: block;
      height: 72px;
      padding-left: 10px;
      padding-right: 10px;
      &__icon{
        background-size: 90%;
        background-position-x: center;
      }
      &__caption{
        position absolute;
        display: inline-block;
        margin-left: 80px;
        margin-top: -64px;
        font-size: 1.6rem;
        margin-right 20px;
      }
    }

  }
  @media(max-width: 568px) { //sm

    .section-title{
      font-size: 2.2rem;
      letter-spacing: 0.425px;
    }
    .case-item{
      &__icon{
        background-size: 75%;
        background-position-x: center;
      }
      &__caption{
        font-size: 1.4rem;
      }
    }
  }
  @media(max-width: 320px) { //xs
    height: auto;

    .wrapper{
      width: 100%;
    }
    .v-spacer{
      height: 0;
    }
    .wrapper_1{
      display: block;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      height: 580px;
    }
    .wrapper_2{
      display: block;
      text-align: center;
      width: 100%;
      height: 100px;
    }
    .section-title{
      font-size: 2.2rem;
      letter-spacing: 0.425px;
    }
    .case-item{
      width: 100%;
      height: 80px;
      font-size: 1.4rem;
      vertical-align: top;
      padding-left: 20px;

      &__icon{
        display: inline-block;
        vertical-align: top;
        margin-right: 0;
        background-size: 66%;
        background-position-x: left;
      }
      &__caption{
        position: absolute;
        display: inline-block;
        width: calc(100% - 120px);
        vertical-align: top;
        margin-left: auto;
        margin-top: 0;
        left: 100px;
      }
    }
  }
}


