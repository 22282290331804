#articles{
  background-color: #F8F8F8;

  .page-title_wrapper{
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media only screen and (min-width: 1270px) {
    .wrapper{
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media only screen and (min-width: 1440px) {
    .wrapper{
      margin-left: auto;
      margin-right: auto;
    }
  }

  .wrapper{
    max-width: 1400px;
  }

  .section-title_wrapper{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media (max-width: 1024px) {
    .section-title_wrapper {
      padding-top: 100px;
    }
  }

  .section-title{
    text-align: center;
    color: #5abad2;
  }
  .btn-more-articles_wrapper{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    align-content: flex-start;
    background-color: #F8F8F8;
    width: 100%;
    transition: .3s;
  }
  .space{
    width: calc(33.33% - 20px);
    height: 470px;
  }
  .tile {
    flex: column wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 40px;
    background-color: #fff;
    width: calc(33.33% - 20px);
    height: 470px;
    box-shadow: 0 0 72px 0 rgba(0, 0, 0, .06);
  }
  @media (max-width: 1546px) {
    .tile{
      box-shadow: none;
    }
  }
  .tile__image{
    display: block;
    width: 100%;
    height: 50%;
    order: 1;
    background-color: #5abad2;
    background-image: url(/i/fon_460.jpg);
    background-position: center center;
    background-size: auto 100%;
    transition: all 1s;
  }
  // not implemented cause throttling
  //.tile__image:hover{
  //  background-size: auto 105%
  //}
  .tile__tint{
    blend-mix-mode: soft-light;
    background-color: #9ad3e6;
    width: 100%;
    height: 100%;
    opacity: 0.72;
    transition: .45s;
  }
  .tile__tint:hover{
    opacity: 0.0;
  }
  .tile__body{
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;
    align-content: stretch;
    height: 50%;
    order: 2;
  }
  .tile__title{
    text-transform: uppercase;
    font-family: 'ProximaNova_Bold', sans-serif;
    font-weight: 600;
    padding: 1.75em 1em 0em 2em;
    line-height: 1.5;
    flex: 1 1 auto;
    order: 1;
  }
  .tile__notice{
    font-family: 'ProximaNova_Regular', sans-serif;
    font-weight: 400;
    padding: 0 1em 0 1em;
    flex: 1 1 auto;
    order: 2;
    overflow: no;
  }
  .tile__button-wrapper{
    flex: 0 1 auto;
    order: 3;
    padding-right: 0.5em;
  }
  .tile__button{
    margin: .5em 0em 1.5em 2em;
    max-width: 180px
  }

  .tile__stats-items{
    padding: 1.45em 1em 1em 0em;
  }
  .tile__stats-item{
    background-repeat: no-repeat;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0.5em;
    display: inline-block
    min-width: 90px;
    float: right;
  }
  .tile__stats-item_time{
    background-image: url('/i/icon-time.svg');
    padding-left: 24px;
    padding-right: 4px;
  }
  .tile__stats-item_pages {
    background-image: url('/i/icon-pages.svg');
    padding-left: 28px;
  }


  @media (min-width: 1024px) {

    .tile_wide{
      display: flex;
      width: calc(66.66% - 10px);
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .tile_wide .tile__image{
      display: flex;
      width: calc(50% - 30px);
      height: 100%;
      flex: 1 0 auto;
    }
    .tile_wide .tile__body{
      display: inline-flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      padding-left: 30px;
      width: 50%;
      height: 100%;
      flex: 1 0 auto;
    }
    .tile_wide .tile__title{
      padding: 2em 1em 1em 1em;
    }
    .tile_wide .tile__notice{
      // no styles
    }
    .tile_wide .tile__button{
      margin: 1em 1em 2em 1em;
    }
    .tile_wide .tile__button-wrapper {

    }
  }

  @media (max-width: 1023px) {
    .tile{
      width: calc(50% - 20px);
    }
    .tile__notice{
      display: none;
    }
  }
  @media (max-width: 768px) {
    .tile{
      font-size: 1.6rem;
    }
  }
  @media (max-width: 568px) {
    .tile{
      width: calc(100%);
    }
    .tile__button-wrapper{
      text-align: center;
    }
  }

}

