.js-expandable-item {

  .expandable-panel {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: .25s;
  }
  .expandable-toggler {
    @extends .btn, .btn_outline-blue
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
  }

  &.is-expanded {
    .expandable-panel {
      overflow: visible;
      max-height: inherit;
      opacity: 1;
    }
    .expandable-toggler {
      display: none;
    }
  }
}
