main.book{

  .prices {
    display: block;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    height: 830px;
    background-color: #59bad1;

    .prices-cards {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 94px;
    }
    .card {
      width: 380px;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 54px 55px 50px 55px;
      box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.16);

      .title {
        color: #59bad1;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
        font-size: 28px;
        font-family: 'ProximaNova_Bold', Sans-Serif;
        font-weight: 700;
        letter-spacing: .66px;
        white-space: nowrap;
      }
      .release-date {
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        font-family: 'ProximaNova_Regular', Sans-Serif;
        color: #59bad1;
      }
      .price {
        text-transform: uppercase;
        text-align: center;
        color: #59bad1;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
        font-size: 28px;
        font-family: 'ProximaNova_Bold', Sans-Serif;
        font-weight: 700;
        letter-spacing: .66px;

        .old {
          font-weight: 500;
          font-family: 'ProximaNova_Regular', Sans-Serif;
          color: #bbb;
        }
        .strike{
          text-decoration: line-through;
        }
      }
      .option-description:before {
        content: '';
        position: absolute;
        background-image: url('/i/icon-success.svg');
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        margin-left: -32px;
      }
      .option-description {
        padding-left: 32px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'ProximaNova_Regular', Sans-Serif;

        strong {
          color: #333;
        }
      }

      .row_1 {
        height: 50px;
      }
      .row_2 {
        height: 80px;
      }
      .row_3 {
        height: 60px;
      }
      .row_4 {
        height: 270px;
      }
      .row_5 {
        text-align: center;

        .btn {
          margin-bottom: 0;
        }
      }
    }
    @media only screen and (max-width: 1279px) {
      .card {
        width: 32%;
        min-width: 300px;
        padding: 24px 35px 40px 35px;
      }
    }
    @media only screen and (max-width: 1023px){
      height: auto;

      .prices-cards {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 32px;

        .card {
          width: 400px;
          margin-bottom: 48px;

          .row_4{
            height: auto;
            padding-bottom: 12px;
          }
        }
      }
    }
    @media only screen and (max-width: 480px){

      .prices-cards {
        padding-top: 48px;
        padding-bottom: 24px;

        .card {
          width: 100%;
          min-width: 280px;
          margin-bottom: 32px;
          padding: 24px 10px 12px 10px;

          .title {
            font-size: 24px;
            letter-spacing: .45px;
            white-space: normal;
          }
          .price {
            font-size: 24px;
            letter-spacing: .45px;
          }
          .option-description{
            font-size: 15px;
          }
        }
      }
    }
  }

}